exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-autores-adrian-diaz-cervera-mdx": () => import("./../../../src/pages/autores/adrian-diaz-cervera.mdx" /* webpackChunkName: "component---src-pages-autores-adrian-diaz-cervera-mdx" */),
  "component---src-pages-autores-alberto-andres-rodriguez-mdx": () => import("./../../../src/pages/autores/alberto-andres-rodriguez.mdx" /* webpackChunkName: "component---src-pages-autores-alberto-andres-rodriguez-mdx" */),
  "component---src-pages-autores-alberto-diaz-martin-mdx": () => import("./../../../src/pages/autores/alberto-diaz-martin.mdx" /* webpackChunkName: "component---src-pages-autores-alberto-diaz-martin-mdx" */),
  "component---src-pages-autores-alberto-escola-fiz-mdx": () => import("./../../../src/pages/autores/alberto-escola-fiz.mdx" /* webpackChunkName: "component---src-pages-autores-alberto-escola-fiz-mdx" */),
  "component---src-pages-autores-alberto-pascual-mdx": () => import("./../../../src/pages/autores/alberto-pascual.mdx" /* webpackChunkName: "component---src-pages-autores-alberto-pascual-mdx" */),
  "component---src-pages-autores-alex-rostan-mdx": () => import("./../../../src/pages/autores/alex-rostan.mdx" /* webpackChunkName: "component---src-pages-autores-alex-rostan-mdx" */),
  "component---src-pages-autores-ana-ines-urrutia-mdx": () => import("./../../../src/pages/autores/ana-ines-urrutia.mdx" /* webpackChunkName: "component---src-pages-autores-ana-ines-urrutia-mdx" */),
  "component---src-pages-autores-andres-ortiz-mdx": () => import("./../../../src/pages/autores/andres-ortiz.mdx" /* webpackChunkName: "component---src-pages-autores-andres-ortiz-mdx" */),
  "component---src-pages-autores-angel-carrillo-mdx": () => import("./../../../src/pages/autores/angel-carrillo.mdx" /* webpackChunkName: "component---src-pages-autores-angel-carrillo-mdx" */),
  "component---src-pages-autores-bruno-torterolo-mdx": () => import("./../../../src/pages/autores/bruno-torterolo.mdx" /* webpackChunkName: "component---src-pages-autores-bruno-torterolo-mdx" */),
  "component---src-pages-autores-christian-buckley-mdx": () => import("./../../../src/pages/autores/christian-buckley.mdx" /* webpackChunkName: "component---src-pages-autores-christian-buckley-mdx" */),
  "component---src-pages-autores-cristian-dragos-fedotov-mdx": () => import("./../../../src/pages/autores/cristian-dragos-fedotov.mdx" /* webpackChunkName: "component---src-pages-autores-cristian-dragos-fedotov-mdx" */),
  "component---src-pages-autores-cristina-quesada-calderon-mdx": () => import("./../../../src/pages/autores/cristina-quesada-calderon.mdx" /* webpackChunkName: "component---src-pages-autores-cristina-quesada-calderon-mdx" */),
  "component---src-pages-autores-daniel-perez-aguiar-mdx": () => import("./../../../src/pages/autores/daniel-perez-aguiar.mdx" /* webpackChunkName: "component---src-pages-autores-daniel-perez-aguiar-mdx" */),
  "component---src-pages-autores-darwin-castro-marin-mdx": () => import("./../../../src/pages/autores/darwin-castro-marin.mdx" /* webpackChunkName: "component---src-pages-autores-darwin-castro-marin-mdx" */),
  "component---src-pages-autores-david-espinosa-mdx": () => import("./../../../src/pages/autores/david-espinosa.mdx" /* webpackChunkName: "component---src-pages-autores-david-espinosa-mdx" */),
  "component---src-pages-autores-david-sanchez-aguilar-mdx": () => import("./../../../src/pages/autores/david-sanchez-aguilar.mdx" /* webpackChunkName: "component---src-pages-autores-david-sanchez-aguilar-mdx" */),
  "component---src-pages-autores-diego-zapico-mdx": () => import("./../../../src/pages/autores/diego-zapico.mdx" /* webpackChunkName: "component---src-pages-autores-diego-zapico-mdx" */),
  "component---src-pages-autores-edgard-alejos-mdx": () => import("./../../../src/pages/autores/edgard-alejos.mdx" /* webpackChunkName: "component---src-pages-autores-edgard-alejos-mdx" */),
  "component---src-pages-autores-edin-kapic-mdx": () => import("./../../../src/pages/autores/edin-kapic.mdx" /* webpackChunkName: "component---src-pages-autores-edin-kapic-mdx" */),
  "component---src-pages-autores-elisa-gutierrez-mdx": () => import("./../../../src/pages/autores/elisa-gutierrez.mdx" /* webpackChunkName: "component---src-pages-autores-elisa-gutierrez-mdx" */),
  "component---src-pages-autores-enrique-sanchez-mdx": () => import("./../../../src/pages/autores/enrique-sanchez.mdx" /* webpackChunkName: "component---src-pages-autores-enrique-sanchez-mdx" */),
  "component---src-pages-autores-fabian-calvo-mdx": () => import("./../../../src/pages/autores/fabian-calvo.mdx" /* webpackChunkName: "component---src-pages-autores-fabian-calvo-mdx" */),
  "component---src-pages-autores-fabian-imaz-mdx": () => import("./../../../src/pages/autores/fabian-imaz.mdx" /* webpackChunkName: "component---src-pages-autores-fabian-imaz-mdx" */),
  "component---src-pages-autores-fernando-chiquiza-mdx": () => import("./../../../src/pages/autores/fernando-chiquiza.mdx" /* webpackChunkName: "component---src-pages-autores-fernando-chiquiza-mdx" */),
  "component---src-pages-autores-ferran-chopo-mdx": () => import("./../../../src/pages/autores/ferran-chopo.mdx" /* webpackChunkName: "component---src-pages-autores-ferran-chopo-mdx" */),
  "component---src-pages-autores-francisco-ricardo-gil-gonzalez-mdx": () => import("./../../../src/pages/autores/francisco-ricardo-gil-gonzalez.mdx" /* webpackChunkName: "component---src-pages-autores-francisco-ricardo-gil-gonzalez-mdx" */),
  "component---src-pages-autores-gaston-cruz-mdx": () => import("./../../../src/pages/autores/gaston-cruz.mdx" /* webpackChunkName: "component---src-pages-autores-gaston-cruz-mdx" */),
  "component---src-pages-autores-gonzalo-marcos-ansoain-mdx": () => import("./../../../src/pages/autores/gonzalo-marcos-ansoain.mdx" /* webpackChunkName: "component---src-pages-autores-gonzalo-marcos-ansoain-mdx" */),
  "component---src-pages-autores-guillermo-bas-mdx": () => import("./../../../src/pages/autores/guillermo-bas.mdx" /* webpackChunkName: "component---src-pages-autores-guillermo-bas-mdx" */),
  "component---src-pages-autores-gustavo-velez-mdx": () => import("./../../../src/pages/autores/gustavo-velez.mdx" /* webpackChunkName: "component---src-pages-autores-gustavo-velez-mdx" */),
  "component---src-pages-autores-haaron-gonzalez-mdx": () => import("./../../../src/pages/autores/haaron-gonzalez.mdx" /* webpackChunkName: "component---src-pages-autores-haaron-gonzalez-mdx" */),
  "component---src-pages-autores-ignasi-tebe-mdx": () => import("./../../../src/pages/autores/ignasi-tebe.mdx" /* webpackChunkName: "component---src-pages-autores-ignasi-tebe-mdx" */),
  "component---src-pages-autores-index-mdx": () => import("./../../../src/pages/autores/index.mdx" /* webpackChunkName: "component---src-pages-autores-index-mdx" */),
  "component---src-pages-autores-israel-urrea-carballo-mdx": () => import("./../../../src/pages/autores/israel-urrea-carballo.mdx" /* webpackChunkName: "component---src-pages-autores-israel-urrea-carballo-mdx" */),
  "component---src-pages-autores-javier-iniesta-mdx": () => import("./../../../src/pages/autores/javier-iniesta.mdx" /* webpackChunkName: "component---src-pages-autores-javier-iniesta-mdx" */),
  "component---src-pages-autores-javier-lopez-gonzalez-mdx": () => import("./../../../src/pages/autores/javier-lopez-gonzalez.mdx" /* webpackChunkName: "component---src-pages-autores-javier-lopez-gonzalez-mdx" */),
  "component---src-pages-autores-javier-menendez-pallo-mdx": () => import("./../../../src/pages/autores/javier-menendez-pallo.mdx" /* webpackChunkName: "component---src-pages-autores-javier-menendez-pallo-mdx" */),
  "component---src-pages-autores-javier-villegas-mdx": () => import("./../../../src/pages/autores/javier-villegas.mdx" /* webpackChunkName: "component---src-pages-autores-javier-villegas-mdx" */),
  "component---src-pages-autores-jorge-castaneda-cano-mdx": () => import("./../../../src/pages/autores/jorge-castaneda-cano.mdx" /* webpackChunkName: "component---src-pages-autores-jorge-castaneda-cano-mdx" */),
  "component---src-pages-autores-jose-angel-bolano-mdx": () => import("./../../../src/pages/autores/jose-angel-bolano.mdx" /* webpackChunkName: "component---src-pages-autores-jose-angel-bolano-mdx" */),
  "component---src-pages-autores-jose-antonio-fraga-sanchez-mdx": () => import("./../../../src/pages/autores/jose-antonio-fraga-sanchez.mdx" /* webpackChunkName: "component---src-pages-autores-jose-antonio-fraga-sanchez-mdx" */),
  "component---src-pages-autores-jose-carlos-rodriguez-aviles-mdx": () => import("./../../../src/pages/autores/jose-carlos-rodriguez-aviles.mdx" /* webpackChunkName: "component---src-pages-autores-jose-carlos-rodriguez-aviles-mdx" */),
  "component---src-pages-autores-jose-gregorio-dorta-luis-mdx": () => import("./../../../src/pages/autores/jose-gregorio-dorta-luis.mdx" /* webpackChunkName: "component---src-pages-autores-jose-gregorio-dorta-luis-mdx" */),
  "component---src-pages-autores-jose-luis-erosa-mdx": () => import("./../../../src/pages/autores/jose-luis-erosa.mdx" /* webpackChunkName: "component---src-pages-autores-jose-luis-erosa-mdx" */),
  "component---src-pages-autores-jose-manuel-redondo-mdx": () => import("./../../../src/pages/autores/jose-manuel-redondo.mdx" /* webpackChunkName: "component---src-pages-autores-jose-manuel-redondo-mdx" */),
  "component---src-pages-autores-jose-quinto-zamora-mdx": () => import("./../../../src/pages/autores/jose-quinto-zamora.mdx" /* webpackChunkName: "component---src-pages-autores-jose-quinto-zamora-mdx" */),
  "component---src-pages-autores-jose-rafael-garcia-mdx": () => import("./../../../src/pages/autores/jose-rafael-garcia.mdx" /* webpackChunkName: "component---src-pages-autores-jose-rafael-garcia-mdx" */),
  "component---src-pages-autores-juan-camilo-martinez-velez-mdx": () => import("./../../../src/pages/autores/juan-camilo-martinez-velez.mdx" /* webpackChunkName: "component---src-pages-autores-juan-camilo-martinez-velez-mdx" */),
  "component---src-pages-autores-juan-carlos-gonzalez-martin-mdx": () => import("./../../../src/pages/autores/juan-carlos-gonzalez-martin.mdx" /* webpackChunkName: "component---src-pages-autores-juan-carlos-gonzalez-martin-mdx" */),
  "component---src-pages-autores-juan-emilio-martinez-mdx": () => import("./../../../src/pages/autores/juan-emilio-martinez.mdx" /* webpackChunkName: "component---src-pages-autores-juan-emilio-martinez-mdx" */),
  "component---src-pages-autores-juan-ignacio-oller-aznar-mdx": () => import("./../../../src/pages/autores/juan-ignacio-oller-aznar.mdx" /* webpackChunkName: "component---src-pages-autores-juan-ignacio-oller-aznar-mdx" */),
  "component---src-pages-autores-juan-pablo-pussacq-mdx": () => import("./../../../src/pages/autores/juan-pablo-pussacq.mdx" /* webpackChunkName: "component---src-pages-autores-juan-pablo-pussacq-mdx" */),
  "component---src-pages-autores-luis-manez-mdx": () => import("./../../../src/pages/autores/luis-manez.mdx" /* webpackChunkName: "component---src-pages-autores-luis-manez-mdx" */),
  "component---src-pages-autores-luis-molina-mdx": () => import("./../../../src/pages/autores/luis-molina.mdx" /* webpackChunkName: "component---src-pages-autores-luis-molina-mdx" */),
  "component---src-pages-autores-luis-valencia-mdx": () => import("./../../../src/pages/autores/luis-valencia.mdx" /* webpackChunkName: "component---src-pages-autores-luis-valencia-mdx" */),
  "component---src-pages-autores-manuel-sanchez-mdx": () => import("./../../../src/pages/autores/manuel-sanchez.mdx" /* webpackChunkName: "component---src-pages-autores-manuel-sanchez-mdx" */),
  "component---src-pages-autores-mar-pedroche-mdx": () => import("./../../../src/pages/autores/mar-pedroche.mdx" /* webpackChunkName: "component---src-pages-autores-mar-pedroche-mdx" */),
  "component---src-pages-autores-marc-baguena-mdx": () => import("./../../../src/pages/autores/marc-baguena.mdx" /* webpackChunkName: "component---src-pages-autores-marc-baguena-mdx" */),
  "component---src-pages-autores-mariano-minoli-mdx": () => import("./../../../src/pages/autores/mariano-minoli.mdx" /* webpackChunkName: "component---src-pages-autores-mariano-minoli-mdx" */),
  "component---src-pages-autores-mayra-badyllo-mdx": () => import("./../../../src/pages/autores/mayra-badyllo.mdx" /* webpackChunkName: "component---src-pages-autores-mayra-badyllo-mdx" */),
  "component---src-pages-autores-miguel-angel-garcia-rodriguez-mdx": () => import("./../../../src/pages/autores/miguel-angel-garcia-rodriguez.mdx" /* webpackChunkName: "component---src-pages-autores-miguel-angel-garcia-rodriguez-mdx" */),
  "component---src-pages-autores-miguel-tabera-pacheco-mdx": () => import("./../../../src/pages/autores/miguel-tabera-pacheco.mdx" /* webpackChunkName: "component---src-pages-autores-miguel-tabera-pacheco-mdx" */),
  "component---src-pages-autores-pablo-di-loreto-mdx": () => import("./../../../src/pages/autores/pablo-di-loreto.mdx" /* webpackChunkName: "component---src-pages-autores-pablo-di-loreto-mdx" */),
  "component---src-pages-autores-robert-bermejo-mdx": () => import("./../../../src/pages/autores/robert-bermejo.mdx" /* webpackChunkName: "component---src-pages-autores-robert-bermejo-mdx" */),
  "component---src-pages-autores-rocio-romero-mdx": () => import("./../../../src/pages/autores/rocio-romero.mdx" /* webpackChunkName: "component---src-pages-autores-rocio-romero-mdx" */),
  "component---src-pages-autores-rodolfo-castro-mdx": () => import("./../../../src/pages/autores/rodolfo-castro.mdx" /* webpackChunkName: "component---src-pages-autores-rodolfo-castro-mdx" */),
  "component---src-pages-autores-rodrigo-liberoff-mdx": () => import("./../../../src/pages/autores/rodrigo-liberoff.mdx" /* webpackChunkName: "component---src-pages-autores-rodrigo-liberoff-mdx" */),
  "component---src-pages-autores-ruben-toribio-mdx": () => import("./../../../src/pages/autores/ruben-toribio.mdx" /* webpackChunkName: "component---src-pages-autores-ruben-toribio-mdx" */),
  "component---src-pages-autores-samantha-villarreal-torres-mdx": () => import("./../../../src/pages/autores/samantha-villarreal-torres.mdx" /* webpackChunkName: "component---src-pages-autores-samantha-villarreal-torres-mdx" */),
  "component---src-pages-autores-santiago-porras-rodriguez-mdx": () => import("./../../../src/pages/autores/santiago-porras-rodriguez.mdx" /* webpackChunkName: "component---src-pages-autores-santiago-porras-rodriguez-mdx" */),
  "component---src-pages-autores-sergio-hernandez-mancebo-mdx": () => import("./../../../src/pages/autores/sergio-hernandez-mancebo.mdx" /* webpackChunkName: "component---src-pages-autores-sergio-hernandez-mancebo-mdx" */),
  "component---src-pages-autores-sergio-parra-mdx": () => import("./../../../src/pages/autores/sergio-parra.mdx" /* webpackChunkName: "component---src-pages-autores-sergio-parra-mdx" */),
  "component---src-pages-autores-victor-moreno-mdx": () => import("./../../../src/pages/autores/victor-moreno.mdx" /* webpackChunkName: "component---src-pages-autores-victor-moreno-mdx" */),
  "component---src-pages-autores-vielka-rojas-mdx": () => import("./../../../src/pages/autores/vielka-rojas.mdx" /* webpackChunkName: "component---src-pages-autores-vielka-rojas-mdx" */),
  "component---src-pages-autores-yina-arenas-mdx": () => import("./../../../src/pages/autores/yina-arenas.mdx" /* webpackChunkName: "component---src-pages-autores-yina-arenas-mdx" */),
  "component---src-pages-especial-50-enero-mdx": () => import("./../../../src/pages/especial-50/enero.mdx" /* webpackChunkName: "component---src-pages-especial-50-enero-mdx" */),
  "component---src-pages-especial-50-febrero-mdx": () => import("./../../../src/pages/especial-50/febrero.mdx" /* webpackChunkName: "component---src-pages-especial-50-febrero-mdx" */),
  "component---src-pages-especial-50-index-mdx": () => import("./../../../src/pages/especial-50/index.mdx" /* webpackChunkName: "component---src-pages-especial-50-index-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-keywords-js": () => import("./../../../src/pages/keywords.js" /* webpackChunkName: "component---src-pages-keywords-js" */),
  "component---src-pages-la-revista-index-mdx": () => import("./../../../src/pages/la-revista/index.mdx" /* webpackChunkName: "component---src-pages-la-revista-index-mdx" */),
  "component---src-pages-partners-encamina-mdx": () => import("./../../../src/pages/partners/encamina.mdx" /* webpackChunkName: "component---src-pages-partners-encamina-mdx" */),
  "component---src-pages-partners-guitaca-mdx": () => import("./../../../src/pages/partners/guitaca.mdx" /* webpackChunkName: "component---src-pages-partners-guitaca-mdx" */),
  "component---src-pages-revistas-index-mdx": () => import("./../../../src/pages/revistas/index.mdx" /* webpackChunkName: "component---src-pages-revistas-index-mdx" */),
  "component---src-pages-revistas-numero-1-index-mdx": () => import("./../../../src/pages/revistas/numero-1/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-1-index-mdx" */),
  "component---src-pages-revistas-numero-10-index-mdx": () => import("./../../../src/pages/revistas/numero-10/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-10-index-mdx" */),
  "component---src-pages-revistas-numero-11-index-mdx": () => import("./../../../src/pages/revistas/numero-11/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-11-index-mdx" */),
  "component---src-pages-revistas-numero-12-index-mdx": () => import("./../../../src/pages/revistas/numero-12/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-12-index-mdx" */),
  "component---src-pages-revistas-numero-13-index-mdx": () => import("./../../../src/pages/revistas/numero-13/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-13-index-mdx" */),
  "component---src-pages-revistas-numero-14-10-puntos-para-enamorarse-de-project-server-2013-mdx": () => import("./../../../src/pages/revistas/numero-14/10-puntos-para-enamorarse-de-project-server-2013.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-14-10-puntos-para-enamorarse-de-project-server-2013-mdx" */),
  "component---src-pages-revistas-numero-14-columnas-con-tipo-de-dato-no-simple-mdx": () => import("./../../../src/pages/revistas/numero-14/columnas-con-tipo-de-dato-no-simple.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-14-columnas-con-tipo-de-dato-no-simple-mdx" */),
  "component---src-pages-revistas-numero-14-compartimoss-en-windows-8-mdx": () => import("./../../../src/pages/revistas/numero-14/compartimoss-en-windows-8.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-14-compartimoss-en-windows-8-mdx" */),
  "component---src-pages-revistas-numero-14-configurando-el-control-taxonomywebtaggingcontrol-mdx": () => import("./../../../src/pages/revistas/numero-14/configurando-el-control-taxonomywebtaggingcontrol.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-14-configurando-el-control-taxonomywebtaggingcontrol-mdx" */),
  "component---src-pages-revistas-numero-14-design-manager-de-sharepoint-2013-mdx": () => import("./../../../src/pages/revistas/numero-14/design-manager-de-sharepoint-2013.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-14-design-manager-de-sharepoint-2013-mdx" */),
  "component---src-pages-revistas-numero-14-el-desafio-empresarial-de-la-gestion-de-procesos-en-sharepoint-mdx": () => import("./../../../src/pages/revistas/numero-14/el-desafio-empresarial-de-la-gestion-de-procesos-en-sharepoint.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-14-el-desafio-empresarial-de-la-gestion-de-procesos-en-sharepoint-mdx" */),
  "component---src-pages-revistas-numero-14-entrevista-a-mario-cortes-mdx": () => import("./../../../src/pages/revistas/numero-14/entrevista-a-mario-cortes.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-14-entrevista-a-mario-cortes-mdx" */),
  "component---src-pages-revistas-numero-14-index-mdx": () => import("./../../../src/pages/revistas/numero-14/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-14-index-mdx" */),
  "component---src-pages-revistas-numero-14-levapoint-mdx": () => import("./../../../src/pages/revistas/numero-14/levapoint.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-14-levapoint-mdx" */),
  "component---src-pages-revistas-numero-14-metodologia-de-trabajo-para-el-desarrollo-de-una-intranet-corporativa-mdx": () => import("./../../../src/pages/revistas/numero-14/metodologia-de-trabajo-para-el-desarrollo-de-una-intranet-corporativa.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-14-metodologia-de-trabajo-para-el-desarrollo-de-una-intranet-corporativa-mdx" */),
  "component---src-pages-revistas-numero-14-napa-mdx": () => import("./../../../src/pages/revistas/numero-14/napa.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-14-napa-mdx" */),
  "component---src-pages-revistas-numero-14-niveles-de-madurez-de-sharepoint-mdx": () => import("./../../../src/pages/revistas/numero-14/niveles-de-madurez-de-sharepoint.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-14-niveles-de-madurez-de-sharepoint-mdx" */),
  "component---src-pages-revistas-numero-14-notificaciones-a-aplicaciones-windows-phone-desde-sharepoint-parte-i-mdx": () => import("./../../../src/pages/revistas/numero-14/notificaciones-a-aplicaciones-windows-phone-desde-sharepoint-parte-i.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-14-notificaciones-a-aplicaciones-windows-phone-desde-sharepoint-parte-i-mdx" */),
  "component---src-pages-revistas-numero-14-novedades-seo-para-sharepoint-2013-mdx": () => import("./../../../src/pages/revistas/numero-14/novedades-seo-para-sharepoint-2013.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-14-novedades-seo-para-sharepoint-2013-mdx" */),
  "component---src-pages-revistas-numero-15-cargar-documentos-en-sharepoint-utilizando-servicios-web-mdx": () => import("./../../../src/pages/revistas/numero-15/cargar-documentos-en-sharepoint-utilizando-servicios-web.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-15-cargar-documentos-en-sharepoint-utilizando-servicios-web-mdx" */),
  "component---src-pages-revistas-numero-15-como-se-hizo-compartimoss-com-parte-i-mdx": () => import("./../../../src/pages/revistas/numero-15/como-se-hizo-compartimoss-com–parte-i.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-15-como-se-hizo-compartimoss-com-parte-i-mdx" */),
  "component---src-pages-revistas-numero-15-customizacion-de-data-views-con-sharepoint-designer-2010-y-visual-studio-2010-mdx": () => import("./../../../src/pages/revistas/numero-15/customizacion-de-data-views-con-sharepoint-designer-2010-y-visual-studio-2010.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-15-customizacion-de-data-views-con-sharepoint-designer-2010-y-visual-studio-2010-mdx" */),
  "component---src-pages-revistas-numero-15-entrevista-a-juan-pablo-pussacq-mdx": () => import("./../../../src/pages/revistas/numero-15/entrevista-a-juan-pablo-pussacq.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-15-entrevista-a-juan-pablo-pussacq-mdx" */),
  "component---src-pages-revistas-numero-15-governance-q-a-mdx": () => import("./../../../src/pages/revistas/numero-15/governance-q-a.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-15-governance-q-a-mdx" */),
  "component---src-pages-revistas-numero-15-index-mdx": () => import("./../../../src/pages/revistas/numero-15/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-15-index-mdx" */),
  "component---src-pages-revistas-numero-15-integracion-con-mapas-y-client-side-rendering-en-sharepoint-2013-parte-i-mdx": () => import("./../../../src/pages/revistas/numero-15/integracion-con-mapas-y-client-side-rendering-en-sharepoint-2013-parte-i.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-15-integracion-con-mapas-y-client-side-rendering-en-sharepoint-2013-parte-i-mdx" */),
  "component---src-pages-revistas-numero-15-lecciones-aprendidas-de-un-proyecto-de-workflow-en-project-server-2010-mdx": () => import("./../../../src/pages/revistas/numero-15/lecciones-aprendidas-de-un-proyecto-de-workflow-en-project-server-2010.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-15-lecciones-aprendidas-de-un-proyecto-de-workflow-en-project-server-2010-mdx" */),
  "component---src-pages-revistas-numero-15-manejadores-de-eventos-remotos-mdx": () => import("./../../../src/pages/revistas/numero-15/manejadores-de-eventos-remotos.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-15-manejadores-de-eventos-remotos-mdx" */),
  "component---src-pages-revistas-numero-15-mvc-para-sharepointeros-parte-i-mdx": () => import("./../../../src/pages/revistas/numero-15/mvc-para-sharepointeros-parte-i.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-15-mvc-para-sharepointeros-parte-i-mdx" */),
  "component---src-pages-revistas-numero-15-notificaciones-a-aplicaciones-windows-phone-desde-sharepoint-parte-ii-mdx": () => import("./../../../src/pages/revistas/numero-15/notificaciones-a-aplicaciones-windows-phone-desde-sharepoint-parte-ii.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-15-notificaciones-a-aplicaciones-windows-phone-desde-sharepoint-parte-ii-mdx" */),
  "component---src-pages-revistas-numero-15-sharepoint-como-sistema-colaborativo-mdx": () => import("./../../../src/pages/revistas/numero-15/sharepoint-como-sistema-colaborativo.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-15-sharepoint-como-sistema-colaborativo-mdx" */),
  "component---src-pages-revistas-numero-15-un-nuevo-csom-el-mobile-object-model-de-sharepoint-2013-mdx": () => import("./../../../src/pages/revistas/numero-15/un-nuevo-csom-el-mobile-object-model-de-sharepoint-2013.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-15-un-nuevo-csom-el-mobile-object-model-de-sharepoint-2013-mdx" */),
  "component---src-pages-revistas-numero-15-workaround-para-permitir-actualizar-un-termino-usando-runwithelevatedprivileges-mdx": () => import("./../../../src/pages/revistas/numero-15/workaround-para-permitir-actualizar-un-término-usando-runwithelevatedprivileges.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-15-workaround-para-permitir-actualizar-un-termino-usando-runwithelevatedprivileges-mdx" */),
  "component---src-pages-revistas-numero-16-agregarle-comentarios-paginas-sharepoint-server-2010-mdx": () => import("./../../../src/pages/revistas/numero-16/agregarle-comentarios-páginas-sharepoint-server-2010.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-16-agregarle-comentarios-paginas-sharepoint-server-2010-mdx" */),
  "component---src-pages-revistas-numero-16-asp-net-mvp-para-sharepointeros-parte-ii-mdx": () => import("./../../../src/pages/revistas/numero-16/asp-net-mvp-para-sharepointeros-parte-ii.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-16-asp-net-mvp-para-sharepointeros-parte-ii-mdx" */),
  "component---src-pages-revistas-numero-16-como-configurar-el-panel-de-refinamiento-sobre-columnas-de-metadatos-multiples-mdx": () => import("./../../../src/pages/revistas/numero-16/como-configurar-el-panel-de-refinamiento-sobre-columnas-de-metadatos-múltiples.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-16-como-configurar-el-panel-de-refinamiento-sobre-columnas-de-metadatos-multiples-mdx" */),
  "component---src-pages-revistas-numero-16-como-se-hizo-compartimoss-parte-ii-mdx": () => import("./../../../src/pages/revistas/numero-16/como-se-hizo-compartimoss-parte-ii.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-16-como-se-hizo-compartimoss-parte-ii-mdx" */),
  "component---src-pages-revistas-numero-16-crear-apps-de-windows-phone-con-contenido-de-sharepoint-parte-i-mdx": () => import("./../../../src/pages/revistas/numero-16/crear-apps-de-windows-phone-con-contenido-de-sharepoint-parte-i.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-16-crear-apps-de-windows-phone-con-contenido-de-sharepoint-parte-i-mdx" */),
  "component---src-pages-revistas-numero-16-diseno-grafico-en-sharepoint-foundation-2010-mdx": () => import("./../../../src/pages/revistas/numero-16/diseno-grafico-en-sharepoint-foundation-2010.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-16-diseno-grafico-en-sharepoint-foundation-2010-mdx" */),
  "component---src-pages-revistas-numero-16-entrevista-a-edin-kapic-mdx": () => import("./../../../src/pages/revistas/numero-16/entrevista-a-edin-kapic.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-16-entrevista-a-edin-kapic-mdx" */),
  "component---src-pages-revistas-numero-16-iberian-sharepoint-conference-mdx": () => import("./../../../src/pages/revistas/numero-16/iberian-sharepoint-conference.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-16-iberian-sharepoint-conference-mdx" */),
  "component---src-pages-revistas-numero-16-index-mdx": () => import("./../../../src/pages/revistas/numero-16/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-16-index-mdx" */),
  "component---src-pages-revistas-numero-16-licenciamiento-de-sharepoint-2013-mdx": () => import("./../../../src/pages/revistas/numero-16/licenciamiento-de-sharepoint-2013.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-16-licenciamiento-de-sharepoint-2013-mdx" */),
  "component---src-pages-revistas-numero-16-monitoreando-sharepoint-server-con-system-center-operations-manager-parte-i-mdx": () => import("./../../../src/pages/revistas/numero-16/monitoreando-sharepoint-server-con-system-center-operations-manager–parte-i.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-16-monitoreando-sharepoint-server-con-system-center-operations-manager-parte-i-mdx" */),
  "component---src-pages-revistas-numero-16-office-sharepoint-app-challenge-de-espana-mdx": () => import("./../../../src/pages/revistas/numero-16/office-sharepoint-app-challenge-de-espana.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-16-office-sharepoint-app-challenge-de-espana-mdx" */),
  "component---src-pages-revistas-numero-16-paginas-de-publicacion-en-sharepoint-server-2013-mdx": () => import("./../../../src/pages/revistas/numero-16/paginas-de-publicacion-en-sharepoint-server-2013.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-16-paginas-de-publicacion-en-sharepoint-server-2013-mdx" */),
  "component---src-pages-revistas-numero-16-sharepoint-designer-2013-y-flujos-de-trabajo-mdx": () => import("./../../../src/pages/revistas/numero-16/sharepoint-designer-2013-y-flujos-de-trabajo.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-16-sharepoint-designer-2013-y-flujos-de-trabajo-mdx" */),
  "component---src-pages-revistas-numero-16-uso-de-frameworks-javascript-en-desarrollos-sharepoint-parte-i-mdx": () => import("./../../../src/pages/revistas/numero-16/uso-de-frameworks-javascript-en-desarrollos-sharepoint-parte-i.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-16-uso-de-frameworks-javascript-en-desarrollos-sharepoint-parte-i-mdx" */),
  "component---src-pages-revistas-numero-17-asp-net-mvc-para-sharepointeros-parte-iii-mdx": () => import("./../../../src/pages/revistas/numero-17/asp-net-mvc-para-sharepointeros-parte-iii.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-17-asp-net-mvc-para-sharepointeros-parte-iii-mdx" */),
  "component---src-pages-revistas-numero-17-clonando-sharepoint-2010-mdx": () => import("./../../../src/pages/revistas/numero-17/clonando-sharepoint-2010.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-17-clonando-sharepoint-2010-mdx" */),
  "component---src-pages-revistas-numero-17-como-configurar-content-type-hub-sharepoint-2010-mdx": () => import("./../../../src/pages/revistas/numero-17/como-configurar-content-type-hub-sharepoint-2010.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-17-como-configurar-content-type-hub-sharepoint-2010-mdx" */),
  "component---src-pages-revistas-numero-17-como-se-hizo-la-app-otic-visor-de-lista-mdx": () => import("./../../../src/pages/revistas/numero-17/como-se-hizo-la-app-otic-visor-de-lista.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-17-como-se-hizo-la-app-otic-visor-de-lista-mdx" */),
  "component---src-pages-revistas-numero-17-configurando-acceso-usuarios-externos-share-point-online-mdx": () => import("./../../../src/pages/revistas/numero-17/Configurando-acceso-usuarios-externos-SharePoint-Online.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-17-configurando-acceso-usuarios-externos-share-point-online-mdx" */),
  "component---src-pages-revistas-numero-17-crear-apps-windows-phone-contenido-sharepoint-parte-ii-mdx": () => import("./../../../src/pages/revistas/numero-17/crear-apps-windows-phone-contenido-sharepoint-parte-ii.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-17-crear-apps-windows-phone-contenido-sharepoint-parte-ii-mdx" */),
  "component---src-pages-revistas-numero-17-documentos-relevantes-limitaciones-por-umbral-mdx": () => import("./../../../src/pages/revistas/numero-17/documentos-relevantes-limitaciones-por-umbral.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-17-documentos-relevantes-limitaciones-por-umbral-mdx" */),
  "component---src-pages-revistas-numero-17-ejemplos-caml-client-object-model-javascript-mdx": () => import("./../../../src/pages/revistas/numero-17/ejemplos-caml-client-object-model-javascript.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-17-ejemplos-caml-client-object-model-javascript-mdx" */),
  "component---src-pages-revistas-numero-17-entrevista-haaron-gonzalez-mdx": () => import("./../../../src/pages/revistas/numero-17/entrevista-haaron-gonzalez.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-17-entrevista-haaron-gonzalez-mdx" */),
  "component---src-pages-revistas-numero-17-index-mdx": () => import("./../../../src/pages/revistas/numero-17/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-17-index-mdx" */),
  "component---src-pages-revistas-numero-17-introduccion-plantillas-elementos-contenido-display-templates-mdx": () => import("./../../../src/pages/revistas/numero-17/introduccion-plantillas-elementos-contenido-display-templates.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-17-introduccion-plantillas-elementos-contenido-display-templates-mdx" */),
  "component---src-pages-revistas-numero-17-novedades-herramientas-desarrollo-office-visual-studio-2013-mdx": () => import("./../../../src/pages/revistas/numero-17/novedades-herramientas-desarrollo-office-visual-studio-2013.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-17-novedades-herramientas-desarrollo-office-visual-studio-2013-mdx" */),
  "component---src-pages-revistas-numero-17-por-que-asistir-a-la-iberian-sharepoint-conference-es-una-idea-estupenda-mdx": () => import("./../../../src/pages/revistas/numero-17/por-que-asistir-a-la-iberian-sharepoint-conference-es-una-idea-estupenda.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-17-por-que-asistir-a-la-iberian-sharepoint-conference-es-una-idea-estupenda-mdx" */),
  "component---src-pages-revistas-numero-17-powershell-cmdlets-crearlos-morir-mdx": () => import("./../../../src/pages/revistas/numero-17/powershell-cmdlets-crearlos-morir.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-17-powershell-cmdlets-crearlos-morir-mdx" */),
  "component---src-pages-revistas-numero-17-records-management-sharepoint-2013-mdx": () => import("./../../../src/pages/revistas/numero-17/records-management-sharepoint-2013.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-17-records-management-sharepoint-2013-mdx" */),
  "component---src-pages-revistas-numero-17-sitios-de-la-comunidad-mdx": () => import("./../../../src/pages/revistas/numero-17/sitios-de-la-comunidad.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-17-sitios-de-la-comunidad-mdx" */),
  "component---src-pages-revistas-numero-17-usar-framework-javascript-en-nuestros-desarrollos-sharepoint-parte-ii-mdx": () => import("./../../../src/pages/revistas/numero-17/usar-framework-javascript-en-nuestros-desarrollos-sharepoint-parte-ii.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-17-usar-framework-javascript-en-nuestros-desarrollos-sharepoint-parte-ii-mdx" */),
  "component---src-pages-revistas-numero-18-administracion-configuracion-flujos-trabajo-mejorar-rendimiento-mdx": () => import("./../../../src/pages/revistas/numero-18/administracion-configuracion-flujos-trabajo-mejorar-rendimiento.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-18-administracion-configuracion-flujos-trabajo-mejorar-rendimiento-mdx" */),
  "component---src-pages-revistas-numero-18-autor-destacado-numero-17-mdx": () => import("./../../../src/pages/revistas/numero-18/autor-destacado-numero-17.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-18-autor-destacado-numero-17-mdx" */),
  "component---src-pages-revistas-numero-18-como-mostrar-mensaje-procesamiento-eventreceiver-mdx": () => import("./../../../src/pages/revistas/numero-18/como-mostrar-mensaje-procesamiento-eventreceiver.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-18-como-mostrar-mensaje-procesamiento-eventreceiver-mdx" */),
  "component---src-pages-revistas-numero-18-comunidad-de-office-365-mdx": () => import("./../../../src/pages/revistas/numero-18/comunidad-de-office-365.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-18-comunidad-de-office-365-mdx" */),
  "component---src-pages-revistas-numero-18-configurando-tu-granja-mas-alla-del-asistente-mdx": () => import("./../../../src/pages/revistas/numero-18/configurando-tu-granja-mas-alla-del-asistente.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-18-configurando-tu-granja-mas-alla-del-asistente-mdx" */),
  "component---src-pages-revistas-numero-18-conversion-documentos-word-sharepoint-2013-mdx": () => import("./../../../src/pages/revistas/numero-18/conversion-documentos-word-sharepoint-2013.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-18-conversion-documentos-word-sharepoint-2013-mdx" */),
  "component---src-pages-revistas-numero-18-entrevista-a-gerardo-reyes-mdx": () => import("./../../../src/pages/revistas/numero-18/entrevista-a-gerardo-reyes.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-18-entrevista-a-gerardo-reyes-mdx" */),
  "component---src-pages-revistas-numero-18-exponiendo-datos-binarios-con-bcs-mdx": () => import("./../../../src/pages/revistas/numero-18/exponiendo-datos-binarios-con-bcs.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-18-exponiendo-datos-binarios-con-bcs-mdx" */),
  "component---src-pages-revistas-numero-18-flujos-trabajo-arquitectura-share-point-server-2013-mdx": () => import("./../../../src/pages/revistas/numero-18/Flujos-Trabajo-Arquitectura-SharePoint-Server-2013.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-18-flujos-trabajo-arquitectura-share-point-server-2013-mdx" */),
  "component---src-pages-revistas-numero-18-iberian-sharepoint-conference-resumen-mdx": () => import("./../../../src/pages/revistas/numero-18/iberian-sharepoint-conference-resumen.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-18-iberian-sharepoint-conference-resumen-mdx" */),
  "component---src-pages-revistas-numero-18-index-mdx": () => import("./../../../src/pages/revistas/numero-18/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-18-index-mdx" */),
  "component---src-pages-revistas-numero-18-intranet-sociales-el-futuro-mdx": () => import("./../../../src/pages/revistas/numero-18/intranet-sociales-el-futuro.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-18-intranet-sociales-el-futuro-mdx" */),
  "component---src-pages-revistas-numero-18-introduccion-al-desarrollo-para-yammer-mdx": () => import("./../../../src/pages/revistas/numero-18/introduccion-al-desarrollo-para-yammer.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-18-introduccion-al-desarrollo-para-yammer-mdx" */),
  "component---src-pages-revistas-numero-18-novedades-herramientas-office-visual-studio-2013-parte-i-mdx": () => import("./../../../src/pages/revistas/numero-18/novedades-herramientas-office-visual-studio-2013-parte-I.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-18-novedades-herramientas-office-visual-studio-2013-parte-i-mdx" */),
  "component---src-pages-revistas-numero-18-olvidar-o-no-olvidar-no-olvidar-utiliza-sharepoint-y-simplemente-recuerda-mdx": () => import("./../../../src/pages/revistas/numero-18/olvidar-o-no-olvidar-no-olvidar-utiliza-sharepoint-y-simplemente-recuerda.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-18-olvidar-o-no-olvidar-no-olvidar-utiliza-sharepoint-y-simplemente-recuerda-mdx" */),
  "component---src-pages-revistas-numero-18-sitios-web-publicos-sharepoint-online-vs-sharepoint-server-2013-mdx": () => import("./../../../src/pages/revistas/numero-18/sitios-web-publicos-sharepoint-online-vs-sharepoint-server-2013.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-18-sitios-web-publicos-sharepoint-online-vs-sharepoint-server-2013-mdx" */),
  "component---src-pages-revistas-numero-18-xsl-transformar-presentar-vistas-datos-share-point-2010-mdx": () => import("./../../../src/pages/revistas/numero-18/XSL-transformar-presentar-vistas-datos-SharePoint-2010.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-18-xsl-transformar-presentar-vistas-datos-share-point-2010-mdx" */),
  "component---src-pages-revistas-numero-19-analisis-de-soluciones-sharepoint-con-msocaf-mdx": () => import("./../../../src/pages/revistas/numero-19/analisis-de-soluciones-sharepoint-con-msocaf.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-19-analisis-de-soluciones-sharepoint-con-msocaf-mdx" */),
  "component---src-pages-revistas-numero-19-buenas-practicas-en-infraestructura-en-sharepoint-2013-parte-1-mdx": () => import("./../../../src/pages/revistas/numero-19/buenas-practicas-en-infraestructura-en-sharepoint-2013-parte-1.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-19-buenas-practicas-en-infraestructura-en-sharepoint-2013-parte-1-mdx" */),
  "component---src-pages-revistas-numero-19-como-actualizar-los-perfiles-de-usuario-en-sharepoint-2013-usando-el-antiguo-web-service-mdx": () => import("./../../../src/pages/revistas/numero-19/como-actualizar-los-perfiles-de-usuario-en-sharepoint-2013-usando-el-antiguo-web-service.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-19-como-actualizar-los-perfiles-de-usuario-en-sharepoint-2013-usando-el-antiguo-web-service-mdx" */),
  "component---src-pages-revistas-numero-19-como-hace-un-flujo-de-trabajo-de-aprobacion-con-sharepoint-designer-2013-mdx": () => import("./../../../src/pages/revistas/numero-19/como-hace-un-flujo-de-trabajo-de-aprobacion-con-sharepoint-designer-2013.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-19-como-hace-un-flujo-de-trabajo-de-aprobacion-con-sharepoint-designer-2013-mdx" */),
  "component---src-pages-revistas-numero-19-como-se-hicieron-las-apps-de-compartimoss-para-android-e-ios-mdx": () => import("./../../../src/pages/revistas/numero-19/como-se-hicieron-las-apps-de-compartimoss-para-android-e-ios.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-19-como-se-hicieron-las-apps-de-compartimoss-para-android-e-ios-mdx" */),
  "component---src-pages-revistas-numero-19-cuando-un-nivel-no-es-suficiente-mdx": () => import("./../../../src/pages/revistas/numero-19/cuando-un-nivel-no-es-suficiente.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-19-cuando-un-nivel-no-es-suficiente-mdx" */),
  "component---src-pages-revistas-numero-19-diseno-grafico-en-sharepoint-foundation-2010-ii-mdx": () => import("./../../../src/pages/revistas/numero-19/diseno-grafico-en-sharepoint-foundation-2010-ii.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-19-diseno-grafico-en-sharepoint-foundation-2010-ii-mdx" */),
  "component---src-pages-revistas-numero-19-el-autor-destacado-del-numero-18-mdx": () => import("./../../../src/pages/revistas/numero-19/el-autor-destacado-del-numero-18.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-19-el-autor-destacado-del-numero-18-mdx" */),
  "component---src-pages-revistas-numero-19-entrevista-a-miguel-tabera-mdx": () => import("./../../../src/pages/revistas/numero-19/entrevista-a-miguel-tabera.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-19-entrevista-a-miguel-tabera-mdx" */),
  "component---src-pages-revistas-numero-19-exchange-online-troubleshooting-mdx": () => import("./../../../src/pages/revistas/numero-19/exchange-online-troubleshooting.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-19-exchange-online-troubleshooting-mdx" */),
  "component---src-pages-revistas-numero-19-index-mdx": () => import("./../../../src/pages/revistas/numero-19/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-19-index-mdx" */),
  "component---src-pages-revistas-numero-19-manejadores-de-eventos-de-seguridad-en-sharepoint-2013-mdx": () => import("./../../../src/pages/revistas/numero-19/manejadores-de-eventos-de-seguridad-en-sharepoint-2013.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-19-manejadores-de-eventos-de-seguridad-en-sharepoint-2013-mdx" */),
  "component---src-pages-revistas-numero-19-renderizado-desde-el-lado-de-cliente-en-sharepoint-2013-mdx": () => import("./../../../src/pages/revistas/numero-19/renderizado-desde-el-lado-de-cliente-en-sharepoint-2013.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-19-renderizado-desde-el-lado-de-cliente-en-sharepoint-2013-mdx" */),
  "component---src-pages-revistas-numero-2-index-mdx": () => import("./../../../src/pages/revistas/numero-2/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-2-index-mdx" */),
  "component---src-pages-revistas-numero-20-ajuste-de-la-relevancia-en-el-motor-de-busqueda-de-sharepoint-2013-mdx": () => import("./../../../src/pages/revistas/numero-20/ajuste-de-la-relevancia-en-el-motor-de-busqueda-de-sharepoint-2013.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-20-ajuste-de-la-relevancia-en-el-motor-de-busqueda-de-sharepoint-2013-mdx" */),
  "component---src-pages-revistas-numero-20-buenas-practicas-en-infraestructura-en-sharepoint-2013-parte-ii-mdx": () => import("./../../../src/pages/revistas/numero-20/buenas-practicas-en-infraestructura-en-sharepoint-2013-parte-ii.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-20-buenas-practicas-en-infraestructura-en-sharepoint-2013-parte-ii-mdx" */),
  "component---src-pages-revistas-numero-20-consumir-servicios-rest-sharepoint-online-2013-desde-workflow-sharepoint-designer-2013-mdx": () => import("./../../../src/pages/revistas/numero-20/consumir-servicios-rest-sharepoint-online-2013-desde-workflow-sharepoint-designer-2013.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-20-consumir-servicios-rest-sharepoint-online-2013-desde-workflow-sharepoint-designer-2013-mdx" */),
  "component---src-pages-revistas-numero-20-el-autor-destacado-del-numero-19-mdx": () => import("./../../../src/pages/revistas/numero-20/el-autor-destacado-del-numero-19.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-20-el-autor-destacado-del-numero-19-mdx" */),
  "component---src-pages-revistas-numero-20-entrevista-a-andres-rojas-mdx": () => import("./../../../src/pages/revistas/numero-20/entrevista-a-andres-rojas.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-20-entrevista-a-andres-rojas-mdx" */),
  "component---src-pages-revistas-numero-20-exponiendo-datos-binarios-con-bcs-parte-ii-mdx": () => import("./../../../src/pages/revistas/numero-20/exponiendo-datos-binarios-con-bcs-parte-ii.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-20-exponiendo-datos-binarios-con-bcs-parte-ii-mdx" */),
  "component---src-pages-revistas-numero-20-index-mdx": () => import("./../../../src/pages/revistas/numero-20/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-20-index-mdx" */),
  "component---src-pages-revistas-numero-20-novedades-en-el-manejo-de-bibliotecas-de-documentos-en-sharepoint-online-mdx": () => import("./../../../src/pages/revistas/numero-20/novedades-en-el-manejo-de-bibliotecas-de-documentos-en-sharepoint-online.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-20-novedades-en-el-manejo-de-bibliotecas-de-documentos-en-sharepoint-online-mdx" */),
  "component---src-pages-revistas-numero-20-novedades-en-sql-server-2014-mdx": () => import("./../../../src/pages/revistas/numero-20/novedades-en-sql-server-2014.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-20-novedades-en-sql-server-2014-mdx" */),
  "component---src-pages-revistas-numero-20-nuevas-api-para-desarrolladores-en-office-365-parte-i-mdx": () => import("./../../../src/pages/revistas/numero-20/nuevas-api-para-desarrolladores-en-office-365-parte-i.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-20-nuevas-api-para-desarrolladores-en-office-365-parte-i-mdx" */),
  "component---src-pages-revistas-numero-20-paginas-maestras-y-aplicaciones-sharepoint-hosted-mdx": () => import("./../../../src/pages/revistas/numero-20/paginas-maestras-y-aplicaciones-sharepoint-hosted.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-20-paginas-maestras-y-aplicaciones-sharepoint-hosted-mdx" */),
  "component---src-pages-revistas-numero-20-sharepoint-2010-deferred-site-collection-upgrade-with-custom-sharepoint-solutions-mdx": () => import("./../../../src/pages/revistas/numero-20/sharepoint-2010-deferred-site-collection-upgrade-with-custom-sharepoint-solutions.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-20-sharepoint-2010-deferred-site-collection-upgrade-with-custom-sharepoint-solutions-mdx" */),
  "component---src-pages-revistas-numero-20-sharepoint-2013-sobre-azure-iaas-mdx": () => import("./../../../src/pages/revistas/numero-20/sharepoint-2013-sobre-azure-iaas.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-20-sharepoint-2013-sobre-azure-iaas-mdx" */),
  "component---src-pages-revistas-numero-20-sharepoint-2013-solucion-o-aplicacion-mdx": () => import("./../../../src/pages/revistas/numero-20/sharepoint-2013-solucion-o-aplicacion.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-20-sharepoint-2013-solucion-o-aplicacion-mdx" */),
  "component---src-pages-revistas-numero-21-administracion-de-sharepoint-online-mediante-powershell-mdx": () => import("./../../../src/pages/revistas/numero-21/administracion-de-sharepoint-online-mediante-powershell.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-21-administracion-de-sharepoint-online-mediante-powershell-mdx" */),
  "component---src-pages-revistas-numero-21-arquitectura-sharepoint-distribuida-en-azure-mdx": () => import("./../../../src/pages/revistas/numero-21/arquitectura-sharepoint-distribuida-en-azure.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-21-arquitectura-sharepoint-distribuida-en-azure-mdx" */),
  "component---src-pages-revistas-numero-21-automatizaciones-para-editar-el-iis-en-sharepoint-2010-2013-mdx": () => import("./../../../src/pages/revistas/numero-21/automatizaciones-para-editar-el-iis-en-sharepoint-2010-2013.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-21-automatizaciones-para-editar-el-iis-en-sharepoint-2010-2013-mdx" */),
  "component---src-pages-revistas-numero-21-creacion-de-soluciones-personalizadas-y-automatizadas-con-nintex-mdx": () => import("./../../../src/pages/revistas/numero-21/creacion-de-soluciones-personalizadas-y-automatizadas-con-nintex.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-21-creacion-de-soluciones-personalizadas-y-automatizadas-con-nintex-mdx" */),
  "component---src-pages-revistas-numero-21-diccionarios-para-el-motor-de-busqueda-de-sharepoint-2013-mdx": () => import("./../../../src/pages/revistas/numero-21/diccionarios-para-el-motor-de-busqueda-de-sharepoint-2013.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-21-diccionarios-para-el-motor-de-busqueda-de-sharepoint-2013-mdx" */),
  "component---src-pages-revistas-numero-21-diseno-grafico-en-sharepoint-foundation-2010-parte-3-mdx": () => import("./../../../src/pages/revistas/numero-21/diseno-grafico-en-sharepoint-foundation-2010-parte-3.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-21-diseno-grafico-en-sharepoint-foundation-2010-parte-3-mdx" */),
  "component---src-pages-revistas-numero-21-el-autor-destacado-del-numero-20-mdx": () => import("./../../../src/pages/revistas/numero-21/el-autor-destacado-del-numero-20.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-21-el-autor-destacado-del-numero-20-mdx" */),
  "component---src-pages-revistas-numero-21-entrevista-a-adrian-diaz-mdx": () => import("./../../../src/pages/revistas/numero-21/entrevista-a-adrian-diaz.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-21-entrevista-a-adrian-diaz-mdx" */),
  "component---src-pages-revistas-numero-21-gobernabilidad-de-seguridad-en-sharepoint-para-la-plataforma-mdx": () => import("./../../../src/pages/revistas/numero-21/gobernabilidad-de-seguridad-en-sharepoint-para-la-plataforma.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-21-gobernabilidad-de-seguridad-en-sharepoint-para-la-plataforma-mdx" */),
  "component---src-pages-revistas-numero-21-index-mdx": () => import("./../../../src/pages/revistas/numero-21/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-21-index-mdx" */),
  "component---src-pages-revistas-numero-21-introduccion-basica-a-las-app-forms-mdx": () => import("./../../../src/pages/revistas/numero-21/introduccion-basica-a-las-app-forms.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-21-introduccion-basica-a-las-app-forms-mdx" */),
  "component---src-pages-revistas-numero-21-nuevas-apis-para-desarrolladores-en-office-365-parte-ii-mdx": () => import("./../../../src/pages/revistas/numero-21/nuevas-apis-para-desarrolladores-en-office-365-parte-ii.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-21-nuevas-apis-para-desarrolladores-en-office-365-parte-ii-mdx" */),
  "component---src-pages-revistas-numero-21-publicando-un-sitio-de-sharepoint-en-cyberoam-mdx": () => import("./../../../src/pages/revistas/numero-21/publicando-un-sitio-de-sharepoint-en-cyberoam.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-21-publicando-un-sitio-de-sharepoint-en-cyberoam-mdx" */),
  "component---src-pages-revistas-numero-21-the-shift-toward-personalized-and-intelligent-collaboration-mdx": () => import("./../../../src/pages/revistas/numero-21/the-shift-toward-personalized-and-intelligent-collaboration.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-21-the-shift-toward-personalized-and-intelligent-collaboration-mdx" */),
  "component---src-pages-revistas-numero-22-como-utilizar-api-office-365-aplicaciones-multiplataformas-xamarin-mdx": () => import("./../../../src/pages/revistas/numero-22/Como-utilizar-API-Office-365-aplicaciones-multiplataformas-Xamarin.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-22-como-utilizar-api-office-365-aplicaciones-multiplataformas-xamarin-mdx" */),
  "component---src-pages-revistas-numero-22-entrevista-david-sanchez-aguilar-mdx": () => import("./../../../src/pages/revistas/numero-22/entrevista-david-sanchez-aguilar.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-22-entrevista-david-sanchez-aguilar-mdx" */),
  "component---src-pages-revistas-numero-22-esquema-de-sitios-y-permisos-mdx": () => import("./../../../src/pages/revistas/numero-22/esquema-de-sitios-y-permisos.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-22-esquema-de-sitios-y-permisos-mdx" */),
  "component---src-pages-revistas-numero-22-forefront-identity-manager-sincronizacion-usuarios-sharepoint-2010-mdx": () => import("./../../../src/pages/revistas/numero-22/forefront-identity-manager-sincronización-usuarios-sharepoint-2010.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-22-forefront-identity-manager-sincronizacion-usuarios-sharepoint-2010-mdx" */),
  "component---src-pages-revistas-numero-22-herramientas-branding-desarrollar-aplicaciones-sharepoint-elegantes-potentes-mdx": () => import("./../../../src/pages/revistas/numero-22/herramientas-branding-desarrollar-aplicaciones-sharepoint-elegantes-potentes.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-22-herramientas-branding-desarrollar-aplicaciones-sharepoint-elegantes-potentes-mdx" */),
  "component---src-pages-revistas-numero-22-index-mdx": () => import("./../../../src/pages/revistas/numero-22/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-22-index-mdx" */),
  "component---src-pages-revistas-numero-22-introduccion-grupos-office-365-trabaja-como-una-red-parte-i-mdx": () => import("./../../../src/pages/revistas/numero-22/introduccion-grupos-office-365-trabaja-como-una-red-parte-i.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-22-introduccion-grupos-office-365-trabaja-como-una-red-parte-i-mdx" */),
  "component---src-pages-revistas-numero-22-introduccion-machine-learning-office-graph-delve-clutter-mdx": () => import("./../../../src/pages/revistas/numero-22/introduccion-machine-learning-office-graph-delve-clutter.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-22-introduccion-machine-learning-office-graph-delve-clutter-mdx" */),
  "component---src-pages-revistas-numero-22-la-nube-de-sharepoint-on-premises-mdx": () => import("./../../../src/pages/revistas/numero-22/la-nube-de-sharepoint-on-premises.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-22-la-nube-de-sharepoint-on-premises-mdx" */),
  "component---src-pages-revistas-numero-22-las-aplicaciones-high-trust-i-mdx": () => import("./../../../src/pages/revistas/numero-22/las-aplicaciones-high-trust-i.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-22-las-aplicaciones-high-trust-i-mdx" */),
  "component---src-pages-revistas-numero-22-movilidad-sobre-sharepoint-parte-i-mdx": () => import("./../../../src/pages/revistas/numero-22/movilidad-sobre-sharepoint-parte-i.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-22-movilidad-sobre-sharepoint-parte-i-mdx" */),
  "component---src-pages-revistas-numero-22-replicacion-granjas-sharepoint-logshipping-mdx": () => import("./../../../src/pages/revistas/numero-22/replicacion-granjas-sharepoint-logshipping.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-22-replicacion-granjas-sharepoint-logshipping-mdx" */),
  "component---src-pages-revistas-numero-22-rest-webapi-2-sharepoint-2013-introduccion-mdx": () => import("./../../../src/pages/revistas/numero-22/rest-webapi-2-sharepoint-2013-introduccion.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-22-rest-webapi-2-sharepoint-2013-introduccion-mdx" */),
  "component---src-pages-revistas-numero-23-administra-mejor-tus-aplicaciones-de-servicio-mdx": () => import("./../../../src/pages/revistas/numero-23/administra-mejor-tus-aplicaciones-de-servicio.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-23-administra-mejor-tus-aplicaciones-de-servicio-mdx" */),
  "component---src-pages-revistas-numero-23-busqueda-datos-empresariales-sharepoint-2013-parte-i-mdx": () => import("./../../../src/pages/revistas/numero-23/busqueda-datos-empresariales-sharepoint-2013-parte-i.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-23-busqueda-datos-empresariales-sharepoint-2013-parte-i-mdx" */),
  "component---src-pages-revistas-numero-23-calculo-de-costes-en-microsoft-azure-mdx": () => import("./../../../src/pages/revistas/numero-23/calculo-de-costes-en-microsoft-azure.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-23-calculo-de-costes-en-microsoft-azure-mdx" */),
  "component---src-pages-revistas-numero-23-creacion-de-un-chat-para-sharepoint-con-signalr-mdx": () => import("./../../../src/pages/revistas/numero-23/creacion-de-un-chat-para-sharepoint-con-signalr.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-23-creacion-de-un-chat-para-sharepoint-con-signalr-mdx" */),
  "component---src-pages-revistas-numero-23-entrevista-a-jorge-castaneda-cano-mdx": () => import("./../../../src/pages/revistas/numero-23/entrevista-a-jorge-castaneda-cano.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-23-entrevista-a-jorge-castaneda-cano-mdx" */),
  "component---src-pages-revistas-numero-23-importancia-disenar-aplicar-buen-uso-gobernanza-sharepoint-2013-mdx": () => import("./../../../src/pages/revistas/numero-23/importancia-disenar-aplicar-buen-uso-gobernanza-sharepoint-2013.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-23-importancia-disenar-aplicar-buen-uso-gobernanza-sharepoint-2013-mdx" */),
  "component---src-pages-revistas-numero-23-index-mdx": () => import("./../../../src/pages/revistas/numero-23/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-23-index-mdx" */),
  "component---src-pages-revistas-numero-23-integracion-dynamics-crm-2015-con-sharepoint-mdx": () => import("./../../../src/pages/revistas/numero-23/integracion-dynamics-crm-2015-con-sharepoint.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-23-integracion-dynamics-crm-2015-con-sharepoint-mdx" */),
  "component---src-pages-revistas-numero-23-introduccion-al-search-driven-development-en-sharepoint-online-y-sharepoint-2013-mdx": () => import("./../../../src/pages/revistas/numero-23/introduccion-al-search-driven-development-en-sharepoint-online-y-sharepoint-2013.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-23-introduccion-al-search-driven-development-en-sharepoint-online-y-sharepoint-2013-mdx" */),
  "component---src-pages-revistas-numero-23-introduccion-grupos-office-365-trabaja-red-parte-ii-mdx": () => import("./../../../src/pages/revistas/numero-23/Introduccion-Grupos-Office-365-Trabaja-red-Parte-II.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-23-introduccion-grupos-office-365-trabaja-red-parte-ii-mdx" */),
  "component---src-pages-revistas-numero-23-las-aplicaciones-high-trust-ii-mdx": () => import("./../../../src/pages/revistas/numero-23/las-aplicaciones-high-trust-ii.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-23-las-aplicaciones-high-trust-ii-mdx" */),
  "component---src-pages-revistas-numero-23-novedades-en-power-bi-mdx": () => import("./../../../src/pages/revistas/numero-23/novedades-en-power-bi.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-23-novedades-en-power-bi-mdx" */),
  "component---src-pages-revistas-numero-23-programando-la-ribbon-en-sharepoint-2010-mdx": () => import("./../../../src/pages/revistas/numero-23/programando-la-ribbon-en-sharepoint-2010.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-23-programando-la-ribbon-en-sharepoint-2010-mdx" */),
  "component---src-pages-revistas-numero-23-rest-webapi-2-y-sharepoint-2013-webapi-y-odata-mdx": () => import("./../../../src/pages/revistas/numero-23/rest-webapi-2-y-sharepoint-2013-webapi-y-odata.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-23-rest-webapi-2-y-sharepoint-2013-webapi-y-odata-mdx" */),
  "component---src-pages-revistas-numero-24-active-directory-authentication-library-aportacion-desarrollo-office-365-mdx": () => import("./../../../src/pages/revistas/numero-24/active-directory-authentication-library-aportacion-desarrollo-office-365.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-24-active-directory-authentication-library-aportacion-desarrollo-office-365-mdx" */),
  "component---src-pages-revistas-numero-24-busqueda-de-datos-empresariales-en-sharepoint-2013-parte-ii-mdx": () => import("./../../../src/pages/revistas/numero-24/busqueda-de-datos-empresariales-en-sharepoint-2013-parte-ii.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-24-busqueda-de-datos-empresariales-en-sharepoint-2013-parte-ii-mdx" */),
  "component---src-pages-revistas-numero-24-index-mdx": () => import("./../../../src/pages/revistas/numero-24/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-24-index-mdx" */),
  "component---src-pages-revistas-numero-24-la-evolucion-de-office-delve-un-futuro-prometedor-mdx": () => import("./../../../src/pages/revistas/numero-24/la-evolucion-de-office-delve-un-futuro-prometedor.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-24-la-evolucion-de-office-delve-un-futuro-prometedor-mdx" */),
  "component---src-pages-revistas-numero-24-movilidad-sobre-sharepoint-parte-ii-mdx": () => import("./../../../src/pages/revistas/numero-24/movilidad-sobre-sharepoint-parte-ii.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-24-movilidad-sobre-sharepoint-parte-ii-mdx" */),
  "component---src-pages-revistas-numero-24-nuevo-modelo-de-desarrollo-para-sharepoint-el-tiempo-de-las-apps-y-apis-de-cliente-mdx": () => import("./../../../src/pages/revistas/numero-24/nuevo-modelo-de-desarrollo-para-sharepoint-el-tiempo-de-las-apps-y-apis-de-cliente.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-24-nuevo-modelo-de-desarrollo-para-sharepoint-el-tiempo-de-las-apps-y-apis-de-cliente-mdx" */),
  "component---src-pages-revistas-numero-24-protegiendo-vmware-con-azure-site-recovery-mdx": () => import("./../../../src/pages/revistas/numero-24/protegiendo-vmware-con-azure-site-recovery.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-24-protegiendo-vmware-con-azure-site-recovery-mdx" */),
  "component---src-pages-revistas-numero-24-provider-hosted-apss-remote-provisioning-parte-i-mdx": () => import("./../../../src/pages/revistas/numero-24/provider-hosted-apss-remote-provisioning-parte-i.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-24-provider-hosted-apss-remote-provisioning-parte-i-mdx" */),
  "component---src-pages-revistas-numero-25-desarrollo-de-aplicaciones-con-office-365-api-mdx": () => import("./../../../src/pages/revistas/numero-25/desarrollo-de-aplicaciones-con-office-365-api.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-25-desarrollo-de-aplicaciones-con-office-365-api-mdx" */),
  "component---src-pages-revistas-numero-25-generando-maquinas-como-churros-azure-devops-y-powershell-desired-state-configuration-mdx": () => import("./../../../src/pages/revistas/numero-25/generando-maquinas-como-churros-azure-devops-y-powershell-desired-state-configuration.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-25-generando-maquinas-como-churros-azure-devops-y-powershell-desired-state-configuration-mdx" */),
  "component---src-pages-revistas-numero-25-index-mdx": () => import("./../../../src/pages/revistas/numero-25/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-25-index-mdx" */),
  "component---src-pages-revistas-numero-25-la-importancia-de-disenar-y-aplicar-un-buen-uso-de-gobernanza-en-nuestro-entorno-sharepoint-2013-parte-iii-mdx": () => import("./../../../src/pages/revistas/numero-25/la-importancia-de-disenar-y-aplicar-un-buen-uso-de-gobernanza-en-nuestro-entorno-sharepoint-2013-parte-iii.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-25-la-importancia-de-disenar-y-aplicar-un-buen-uso-de-gobernanza-en-nuestro-entorno-sharepoint-2013-parte-iii-mdx" */),
  "component---src-pages-revistas-numero-25-trabajando-programaticamente-con-tipos-de-contenido-mdx": () => import("./../../../src/pages/revistas/numero-25/trabajando-programaticamente-con-tipos-de-contenido.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-25-trabajando-programaticamente-con-tipos-de-contenido-mdx" */),
  "component---src-pages-revistas-numero-26-entrevista-fernando-chiquiza-ramos-mdx": () => import("./../../../src/pages/revistas/numero-26/entrevista-fernando-chiquiza-ramos.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-26-entrevista-fernando-chiquiza-ramos-mdx" */),
  "component---src-pages-revistas-numero-26-extendiendo-los-permisos-de-sharepoint-con-un-proveedor-de-claims-mdx": () => import("./../../../src/pages/revistas/numero-26/extendiendo-los-permisos-de-sharepoint-con-un-proveedor-de-claims.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-26-extendiendo-los-permisos-de-sharepoint-con-un-proveedor-de-claims-mdx" */),
  "component---src-pages-revistas-numero-26-guia-de-supervivencia-para-los-permisos-de-project-server-mdx": () => import("./../../../src/pages/revistas/numero-26/guia-de-supervivencia-para-los-permisos-de-project-server.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-26-guia-de-supervivencia-para-los-permisos-de-project-server-mdx" */),
  "component---src-pages-revistas-numero-26-index-mdx": () => import("./../../../src/pages/revistas/numero-26/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-26-index-mdx" */),
  "component---src-pages-revistas-numero-26-la-busqueda-tambien-es-hibrida-mdx": () => import("./../../../src/pages/revistas/numero-26/la-busqueda-tambien-es-hibrida.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-26-la-busqueda-tambien-es-hibrida-mdx" */),
  "component---src-pages-revistas-numero-26-node-js-para-office-sharepoint-developers-mdx": () => import("./../../../src/pages/revistas/numero-26/node-js-para-office-sharepoint-developers.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-26-node-js-para-office-sharepoint-developers-mdx" */),
  "component---src-pages-revistas-numero-26-nuevos-retos-en-la-gestion-de-entornos-hibridos-para-sharepoint-2016-mdx": () => import("./../../../src/pages/revistas/numero-26/nuevos-retos-en-la-gestion-de-entornos-hibridos-para-sharepoint-2016.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-26-nuevos-retos-en-la-gestion-de-entornos-hibridos-para-sharepoint-2016-mdx" */),
  "component---src-pages-revistas-numero-26-porque-fracasan-los-proyectos-de-sharepoint-mdx": () => import("./../../../src/pages/revistas/numero-26/porque-fracasan-los-proyectos-de-sharepoint.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-26-porque-fracasan-los-proyectos-de-sharepoint-mdx" */),
  "component---src-pages-revistas-numero-26-power-bi-como-servicio-de-self-service-bi-mdx": () => import("./../../../src/pages/revistas/numero-26/power-bi-como-servicio-de-self-service-bi.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-26-power-bi-como-servicio-de-self-service-bi-mdx" */),
  "component---src-pages-revistas-numero-26-sharepoint-y-azure-application-insights-mdx": () => import("./../../../src/pages/revistas/numero-26/sharepoint-y-azure-application-insights.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-26-sharepoint-y-azure-application-insights-mdx" */),
  "component---src-pages-revistas-numero-26-uso-de-patrones-de-diseno-en-la-nube-parte-i-mdx": () => import("./../../../src/pages/revistas/numero-26/uso-de-patrones-de-diseno-en-la-nube-parte-i.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-26-uso-de-patrones-de-diseno-en-la-nube-parte-i-mdx" */),
  "component---src-pages-revistas-numero-27-conectores-para-grupos-de-office-365-mdx": () => import("./../../../src/pages/revistas/numero-27/conectores-para-grupos-de-office-365.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-27-conectores-para-grupos-de-office-365-mdx" */),
  "component---src-pages-revistas-numero-27-configurando-azure-directory-services-para-office-365-mdx": () => import("./../../../src/pages/revistas/numero-27/configurando-azure-directory-services-para-office365.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-27-configurando-azure-directory-services-para-office-365-mdx" */),
  "component---src-pages-revistas-numero-27-conociendo-a-minrole-de-sharepoint-2016-mdx": () => import("./../../../src/pages/revistas/numero-27/conociendo-a-minrole-de-sharepoint-2016.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-27-conociendo-a-minrole-de-sharepoint-2016-mdx" */),
  "component---src-pages-revistas-numero-27-enmarcha-framework-open-source-sharepoint-mdx": () => import("./../../../src/pages/revistas/numero-27/enmarcha-framework-open-source-sharepoint.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-27-enmarcha-framework-open-source-sharepoint-mdx" */),
  "component---src-pages-revistas-numero-27-entrevista-enrique-rhenals-barcenas-mdx": () => import("./../../../src/pages/revistas/numero-27/entrevista-enrique-rhenals-barcenas.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-27-entrevista-enrique-rhenals-barcenas-mdx" */),
  "component---src-pages-revistas-numero-27-es-project-server-online-realmente-multiidioma-mdx": () => import("./../../../src/pages/revistas/numero-27/es-project-server-online-realmente-multiidioma.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-27-es-project-server-online-realmente-multiidioma-mdx" */),
  "component---src-pages-revistas-numero-27-index-mdx": () => import("./../../../src/pages/revistas/numero-27/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-27-index-mdx" */),
  "component---src-pages-revistas-numero-27-infopath-se-muere-mdx": () => import("./../../../src/pages/revistas/numero-27/infopath-se-muere.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-27-infopath-se-muere-mdx" */),
  "component---src-pages-revistas-numero-27-integracion-de-powershell-local-con-powershell-de-azure-mdx": () => import("./../../../src/pages/revistas/numero-27/integracion-de-powershell-local-con-powershell-de-azure.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-27-integracion-de-powershell-local-con-powershell-de-azure-mdx" */),
  "component---src-pages-revistas-numero-27-introduccion-al-pnp-program-y-provisioning-framework-mdx": () => import("./../../../src/pages/revistas/numero-27/introduccion-al-pnp-program-y-provisioning-framework.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-27-introduccion-al-pnp-program-y-provisioning-framework-mdx" */),
  "component---src-pages-revistas-numero-27-particularidades-instalacion-granja-varios-servidores-sharepoint-2016-mdx": () => import("./../../../src/pages/revistas/numero-27/particularidades-instalacion-granja-varios-servidores-sharepoint-2016.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-27-particularidades-instalacion-granja-varios-servidores-sharepoint-2016-mdx" */),
  "component---src-pages-revistas-numero-27-powerapps-creando-nuestra-primera-aplicacion-mdx": () => import("./../../../src/pages/revistas/numero-27/powerapps-creando-nuestra-primera-aplicacion.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-27-powerapps-creando-nuestra-primera-aplicacion-mdx" */),
  "component---src-pages-revistas-numero-27-sharepoint-2013-client-side-rendering-en-detalle-mdx": () => import("./../../../src/pages/revistas/numero-27/sharepoint-2013-client-side-rendering-en-detalle.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-27-sharepoint-2013-client-side-rendering-en-detalle-mdx" */),
  "component---src-pages-revistas-numero-27-sharepoint-y-azure-cloud-services-mdx": () => import("./../../../src/pages/revistas/numero-27/sharepoint-y-azure-cloud-services.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-27-sharepoint-y-azure-cloud-services-mdx" */),
  "component---src-pages-revistas-numero-27-stratusforms-adios-infopath-mdx": () => import("./../../../src/pages/revistas/numero-27/stratusforms-adios-infopath.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-27-stratusforms-adios-infopath-mdx" */),
  "component---src-pages-revistas-numero-27-usando-azure-search-en-soluciones-de-busqueda-para-office-365-mdx": () => import("./../../../src/pages/revistas/numero-27/usando-azure-search-en-soluciones-de-busqueda-para-office-365.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-27-usando-azure-search-en-soluciones-de-busqueda-para-office-365-mdx" */),
  "component---src-pages-revistas-numero-28-configurando-administrando-nano-servers-en-microsoft-azure-mdx": () => import("./../../../src/pages/revistas/numero-28/configurando-administrando-nano-servers-en-microsoft-azure.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-28-configurando-administrando-nano-servers-en-microsoft-azure-mdx" */),
  "component---src-pages-revistas-numero-28-entrevista-erika-vilches-mdx": () => import("./../../../src/pages/revistas/numero-28/entrevista-erika-vilches.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-28-entrevista-erika-vilches-mdx" */),
  "component---src-pages-revistas-numero-28-index-mdx": () => import("./../../../src/pages/revistas/numero-28/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-28-index-mdx" */),
  "component---src-pages-revistas-numero-28-introduccion-a-azure-api-management-mdx": () => import("./../../../src/pages/revistas/numero-28/introduccion-a-azure-api-management.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-28-introduccion-a-azure-api-management-mdx" */),
  "component---src-pages-revistas-numero-28-introduciendo-un-gestor-de-paquetes-de-soluciones-para-sharepoint-mdx": () => import("./../../../src/pages/revistas/numero-28/introduciendo-un-gestor-de-paquetes-de-soluciones-para-sharepoint.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-28-introduciendo-un-gestor-de-paquetes-de-soluciones-para-sharepoint-mdx" */),
  "component---src-pages-revistas-numero-28-microsoft-flow-eventos-y-acciones-para-project-online-mdx": () => import("./../../../src/pages/revistas/numero-28/microsoft-flow-eventos-y-acciones-para-project-online.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-28-microsoft-flow-eventos-y-acciones-para-project-online-mdx" */),
  "component---src-pages-revistas-numero-28-novedades-en-sharepoint-2016-y-office-365-onedrive-for-business-mdx": () => import("./../../../src/pages/revistas/numero-28/novedades-en-sharepoint-2016-y-office-365-onedrive-for-business.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-28-novedades-en-sharepoint-2016-y-office-365-onedrive-for-business-mdx" */),
  "component---src-pages-revistas-numero-28-pnp-provisioning-framework-arquitectura-mdx": () => import("./../../../src/pages/revistas/numero-28/pnp-provisioning-framework-arquitectura.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-28-pnp-provisioning-framework-arquitectura-mdx" */),
  "component---src-pages-revistas-numero-28-que-es-el-sharepoint-framework-mdx": () => import("./../../../src/pages/revistas/numero-28/que-es-el-sharepoint-framework.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-28-que-es-el-sharepoint-framework-mdx" */),
  "component---src-pages-revistas-numero-28-sharepoint-y-azure-azure-batch-mdx": () => import("./../../../src/pages/revistas/numero-28/sharepoint-y-azure-azure-batch.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-28-sharepoint-y-azure-azure-batch-mdx" */),
  "component---src-pages-revistas-numero-28-sharepoint-y-powerapps-gestiona-tus-datos-de-negocio-mdx": () => import("./../../../src/pages/revistas/numero-28/sharepoint-y-powerapps-gestiona-tus-datos-de-negocio.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-28-sharepoint-y-powerapps-gestiona-tus-datos-de-negocio-mdx" */),
  "component---src-pages-revistas-numero-28-un-primer-vistazo-a-la-nueva-experiencia-de-usuario-de-bibliotecas-de-documentos-en-sharepoint-online-mdx": () => import("./../../../src/pages/revistas/numero-28/un-primer-vistazo-a-la-nueva-experiencia-de-usuario-de-bibliotecas-de-documentos-en-sharepoint-online.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-28-un-primer-vistazo-a-la-nueva-experiencia-de-usuario-de-bibliotecas-de-documentos-en-sharepoint-online-mdx" */),
  "component---src-pages-revistas-numero-28-un-sabado-en-la-vida-de-un-speaker-mdx": () => import("./../../../src/pages/revistas/numero-28/un-sabado-en-la-vida-de-un-speaker.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-28-un-sabado-en-la-vida-de-un-speaker-mdx" */),
  "component---src-pages-revistas-numero-28-vinculacion-hojas-excel-onedrive-sharepoint-mdx": () => import("./../../../src/pages/revistas/numero-28/vinculacion-hojas-excel-onedrive-sharepoint.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-28-vinculacion-hojas-excel-onedrive-sharepoint-mdx" */),
  "component---src-pages-revistas-numero-29-azure-information-protection-mdx": () => import("./../../../src/pages/revistas/numero-29/azure-information-protection.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-29-azure-information-protection-mdx" */),
  "component---src-pages-revistas-numero-29-azure-task-scheduler-planifica-tus-procesos-en-azure-mdx": () => import("./../../../src/pages/revistas/numero-29/azure-task-scheduler-planifica-tus-procesos-en-azure.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-29-azure-task-scheduler-planifica-tus-procesos-en-azure-mdx" */),
  "component---src-pages-revistas-numero-29-configuracion-de-enterprise-gateway-en-power-bi-mdx": () => import("./../../../src/pages/revistas/numero-29/configuracion-de-enterprise-gateway-en-power-bi.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-29-configuracion-de-enterprise-gateway-en-power-bi-mdx" */),
  "component---src-pages-revistas-numero-29-custom-extensibility-handlers-para-el-framework-de-provisioning-del-pnp-mdx": () => import("./../../../src/pages/revistas/numero-29/custom-extensibility-handlers-para-el-framework-de-provisioning-del-pnp.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-29-custom-extensibility-handlers-para-el-framework-de-provisioning-del-pnp-mdx" */),
  "component---src-pages-revistas-numero-29-flujos-y-eventos-para-project-server-con-nintex-mdx": () => import("./../../../src/pages/revistas/numero-29/flujos-y-eventos-para-project-server-con-nintex.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-29-flujos-y-eventos-para-project-server-con-nintex-mdx" */),
  "component---src-pages-revistas-numero-29-gestion-de-datos-no-relacionales-en-microsoft-azure-mdx": () => import("./../../../src/pages/revistas/numero-29/gestion-de-datos-no-relacionales-en-microsoft-azure.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-29-gestion-de-datos-no-relacionales-en-microsoft-azure-mdx" */),
  "component---src-pages-revistas-numero-29-index-mdx": () => import("./../../../src/pages/revistas/numero-29/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-29-index-mdx" */),
  "component---src-pages-revistas-numero-29-integra-powerapp-con-microsoft-flow-y-custom-api-mdx": () => import("./../../../src/pages/revistas/numero-29/integra-powerapp-con-microsoft-flow-y-custom-api.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-29-integra-powerapp-con-microsoft-flow-y-custom-api-mdx" */),
  "component---src-pages-revistas-numero-29-microsoft-bot-framework-para-automatizar-conversaciones-mdx": () => import("./../../../src/pages/revistas/numero-29/microsoft-bot-framework-para-automatizar-conversaciones.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-29-microsoft-bot-framework-para-automatizar-conversaciones-mdx" */),
  "component---src-pages-revistas-numero-29-nueva-experiencia-de-usuario-en-listas-en-spo-mdx": () => import("./../../../src/pages/revistas/numero-29/nueva-experiencia-de-usuario-en-listas-en-spo.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-29-nueva-experiencia-de-usuario-en-listas-en-spo-mdx" */),
  "component---src-pages-revistas-numero-29-power-bi-conexion-con-sql-y-oracle-mdx": () => import("./../../../src/pages/revistas/numero-29/power-bi-conexion-con-sql-y-oracle.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-29-power-bi-conexion-con-sql-y-oracle-mdx" */),
  "component---src-pages-revistas-numero-29-sharepoint-y-azure-azure-webjobs-mdx": () => import("./../../../src/pages/revistas/numero-29/sharepoint-y-azure-–-azure-webjobs.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-29-sharepoint-y-azure-azure-webjobs-mdx" */),
  "component---src-pages-revistas-numero-29-unit-testing-en-sharepoint-mdx": () => import("./../../../src/pages/revistas/numero-29/unit-testing-en-sharepoint.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-29-unit-testing-en-sharepoint-mdx" */),
  "component---src-pages-revistas-numero-29-xamarin-forms-y-sharepoint-on-premise-mdx": () => import("./../../../src/pages/revistas/numero-29/xamarin-forms-y-sharepoint-on-premise.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-29-xamarin-forms-y-sharepoint-on-premise-mdx" */),
  "component---src-pages-revistas-numero-3-index-mdx": () => import("./../../../src/pages/revistas/numero-3/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-3-index-mdx" */),
  "component---src-pages-revistas-numero-30-big-data-como-solucion-paas-en-azure-hdinsight-y-hbase-mdx": () => import("./../../../src/pages/revistas/numero-30/big-data-como-solucion-paas-en-azure-hdinsight-y-hbase.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-30-big-data-como-solucion-paas-en-azure-hdinsight-y-hbase-mdx" */),
  "component---src-pages-revistas-numero-30-como-realizar-una-plantilla-en-azure-en-formato-arm-mdx": () => import("./../../../src/pages/revistas/numero-30/como-realizar-una-plantilla-en-azure-en-formato-arm.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-30-como-realizar-una-plantilla-en-azure-en-formato-arm-mdx" */),
  "component---src-pages-revistas-numero-30-documentdb-querys-mdx": () => import("./../../../src/pages/revistas/numero-30/documentdb-querys.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-30-documentdb-querys-mdx" */),
  "component---src-pages-revistas-numero-30-enterprise-mobility-suite-parte-1-introduccion-mdx": () => import("./../../../src/pages/revistas/numero-30/enterprise-mobility-suite-parte-1-introduccion.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-30-enterprise-mobility-suite-parte-1-introduccion-mdx" */),
  "component---src-pages-revistas-numero-30-entrevista-a-yina-arenas-mdx": () => import("./../../../src/pages/revistas/numero-30/entrevista-a-yina-arenas.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-30-entrevista-a-yina-arenas-mdx" */),
  "component---src-pages-revistas-numero-30-guia-practica-sobre-limites-de-uso-y-tamanos-de-sharepoint-mdx": () => import("./../../../src/pages/revistas/numero-30/guia-practica-sobre-limites-de-uso-y-tamanos-de-sharepoint.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-30-guia-practica-sobre-limites-de-uso-y-tamanos-de-sharepoint-mdx" */),
  "component---src-pages-revistas-numero-30-index-mdx": () => import("./../../../src/pages/revistas/numero-30/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-30-index-mdx" */),
  "component---src-pages-revistas-numero-30-integracion-continua-en-sharepoint-con-team-services-y-pnp-mdx": () => import("./../../../src/pages/revistas/numero-30/integracion-continua-en-sharepoint-con-team-services-y-pnp.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-30-integracion-continua-en-sharepoint-con-team-services-y-pnp-mdx" */),
  "component---src-pages-revistas-numero-30-integracion-sharepoint-wordpress-web-hooks-i-mdx": () => import("./../../../src/pages/revistas/numero-30/integracion-sharepoint-wordpress-web-hooks-I.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-30-integracion-sharepoint-wordpress-web-hooks-i-mdx" */),
  "component---src-pages-revistas-numero-30-microsoft-teams-mejorando-colaboracion-office-365-mdx": () => import("./../../../src/pages/revistas/numero-30/microsoft-teams-mejorando-colaboracion-office-365.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-30-microsoft-teams-mejorando-colaboracion-office-365-mdx" */),
  "component---src-pages-revistas-numero-30-power-bi-embbeded-mdx": () => import("./../../../src/pages/revistas/numero-30/power-bi-embbeded.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-30-power-bi-embbeded-mdx" */),
  "component---src-pages-revistas-numero-30-prepara-la-migracion-desde-tus-granjas-onpremises-a-sharepoint-online-con-smat-mdx": () => import("./../../../src/pages/revistas/numero-30/prepara-la-migracion-desde-tus-granjas-onpremises-a-sharepoint-online-con-smat.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-30-prepara-la-migracion-desde-tus-granjas-onpremises-a-sharepoint-online-con-smat-mdx" */),
  "component---src-pages-revistas-numero-30-sharepoint-framework-el-despliegue-mdx": () => import("./../../../src/pages/revistas/numero-30/sharepoint-framework-el-despliegue.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-30-sharepoint-framework-el-despliegue-mdx" */),
  "component---src-pages-revistas-numero-30-sharepoint-y-azure-azure-functions-mdx": () => import("./../../../src/pages/revistas/numero-30/sharepoint-y-azure-azure-functions.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-30-sharepoint-y-azure-azure-functions-mdx" */),
  "component---src-pages-revistas-numero-30-usando-r-script-en-power-bi-mdx": () => import("./../../../src/pages/revistas/numero-30/usando-r-script-en-power-bi.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-30-usando-r-script-en-power-bi-mdx" */),
  "component---src-pages-revistas-numero-31-activacion-opciones-guardado-plantilla-sharepoint-online-mdx": () => import("./../../../src/pages/revistas/numero-31/activacion-opciones-guardado-plantilla-sharepoint-online.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-31-activacion-opciones-guardado-plantilla-sharepoint-online-mdx" */),
  "component---src-pages-revistas-numero-31-api-management-logic-apps-bus-integracion-mdx": () => import("./../../../src/pages/revistas/numero-31/api-management-logic-apps-bus-integracion.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-31-api-management-logic-apps-bus-integracion-mdx" */),
  "component---src-pages-revistas-numero-31-azure-serverless-mdx": () => import("./../../../src/pages/revistas/numero-31/azure-serverless.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-31-azure-serverless-mdx" */),
  "component---src-pages-revistas-numero-31-cloud-pbx-cual-es-la-solucion-adecuada-para-mi-mdx": () => import("./../../../src/pages/revistas/numero-31/cloud-pbx-cual-es-la-solucion-adecuada-para-mi.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-31-cloud-pbx-cual-es-la-solucion-adecuada-para-mi-mdx" */),
  "component---src-pages-revistas-numero-31-control-errores-spfx-out-of-the-box-application-insight-mdx": () => import("./../../../src/pages/revistas/numero-31/control-errores-spfx-out-of-the-box-application-insight.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-31-control-errores-spfx-out-of-the-box-application-insight-mdx" */),
  "component---src-pages-revistas-numero-31-destripando-modern-sites-y-pages-en-sharepoint-online-mdx": () => import("./../../../src/pages/revistas/numero-31/destripando-modern-sites-y-pages-en-sharepoint-online.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-31-destripando-modern-sites-y-pages-en-sharepoint-online-mdx" */),
  "component---src-pages-revistas-numero-31-emulando-documentdb-mdx": () => import("./../../../src/pages/revistas/numero-31/emulando-documentdb.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-31-emulando-documentdb-mdx" */),
  "component---src-pages-revistas-numero-31-enterprise-mobility-suite-parte-2-azure-ad-premium-mdx": () => import("./../../../src/pages/revistas/numero-31/enterprise-mobility-suite-parte-2-azure-ad-premium.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-31-enterprise-mobility-suite-parte-2-azure-ad-premium-mdx" */),
  "component---src-pages-revistas-numero-31-entrevista-a-luis-valencia-mdx": () => import("./../../../src/pages/revistas/numero-31/entrevista-a-luis-valencia.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-31-entrevista-a-luis-valencia-mdx" */),
  "component---src-pages-revistas-numero-31-gobernabilidad-en-power-bi-mdx": () => import("./../../../src/pages/revistas/numero-31/gobernabilidad-en-power-bi.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-31-gobernabilidad-en-power-bi-mdx" */),
  "component---src-pages-revistas-numero-31-index-mdx": () => import("./../../../src/pages/revistas/numero-31/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-31-index-mdx" */),
  "component---src-pages-revistas-numero-31-integracion-de-sharepoint-con-power-bi-mdx": () => import("./../../../src/pages/revistas/numero-31/integracion-de-sharepoint-con-power-bi.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-31-integracion-de-sharepoint-con-power-bi-mdx" */),
  "component---src-pages-revistas-numero-31-integrando-bot-framework-y-microsoft-teams-mdx": () => import("./../../../src/pages/revistas/numero-31/integrando-bot-framework-y-microsoft-teams.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-31-integrando-bot-framework-y-microsoft-teams-mdx" */),
  "component---src-pages-revistas-numero-31-introduccion-a-fasttrack-network-analysis-para-office-365-mdx": () => import("./../../../src/pages/revistas/numero-31/introduccion-a-fasttrack-network-analysis-para-office-365.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-31-introduccion-a-fasttrack-network-analysis-para-office-365-mdx" */),
  "component---src-pages-revistas-numero-31-introducing-microsoft-powerapps-mdx": () => import("./../../../src/pages/revistas/numero-31/introducing-microsoft-powerapps.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-31-introducing-microsoft-powerapps-mdx" */),
  "component---src-pages-revistas-numero-31-microsoft-operations-management-suite-mdx": () => import("./../../../src/pages/revistas/numero-31/microsoft-operations-management-suite.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-31-microsoft-operations-management-suite-mdx" */),
  "component---src-pages-revistas-numero-31-sharepoint-y-azure-azure-content-delivery-network-mdx": () => import("./../../../src/pages/revistas/numero-31/sharepoint-y-azure-azure-content-delivery-network.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-31-sharepoint-y-azure-azure-content-delivery-network-mdx" */),
  "component---src-pages-revistas-numero-31-temperatura-en-tiempo-real-aplicacion-web-con-raspberry-azure-iot-hub-y-signalr-mdx": () => import("./../../../src/pages/revistas/numero-31/temperatura-en-tiempo-real-aplicacion-web-con-raspberry-azure-iot-hub-y-signalr.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-31-temperatura-en-tiempo-real-aplicacion-web-con-raspberry-azure-iot-hub-y-signalr-mdx" */),
  "component---src-pages-revistas-numero-32-depurar-codigo-en-visual-studio-code-wamp-server-y-chrome-mdx": () => import("./../../../src/pages/revistas/numero-32/depurar-codigo-en-visual-studio-code-wamp-server-y-chrome.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-32-depurar-codigo-en-visual-studio-code-wamp-server-y-chrome-mdx" */),
  "component---src-pages-revistas-numero-32-enterprise-mobility-security-mdx": () => import("./../../../src/pages/revistas/numero-32/enterprise-mobility-security.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-32-enterprise-mobility-security-mdx" */),
  "component---src-pages-revistas-numero-32-entrevista-a-peter-frank-mdx": () => import("./../../../src/pages/revistas/numero-32/entrevista-a-peter-frank.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-32-entrevista-a-peter-frank-mdx" */),
  "component---src-pages-revistas-numero-32-envio-de-mensajes-al-iot-hub-con-node-js-mdx": () => import("./../../../src/pages/revistas/numero-32/envio-de-mensajes-al-iot-hub-con-node-js.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-32-envio-de-mensajes-al-iot-hub-con-node-js-mdx" */),
  "component---src-pages-revistas-numero-32-eventos-sobre-sharepoint-online-con-webhooks-mdx": () => import("./../../../src/pages/revistas/numero-32/eventos-sobre-sharepoint-online-con-webhooks.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-32-eventos-sobre-sharepoint-online-con-webhooks-mdx" */),
  "component---src-pages-revistas-numero-32-gestion-de-dispositivos-mac-en-entornos-microsoft-con-sccm-y-pmm-mdx": () => import("./../../../src/pages/revistas/numero-32/gestion-de-dispositivos-mac-en-entornos-microsoft-con-sccm-y-pmm.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-32-gestion-de-dispositivos-mac-en-entornos-microsoft-con-sccm-y-pmm-mdx" */),
  "component---src-pages-revistas-numero-32-how-to-create-an-app-for-daily-tasks-using-microsoft-powerapps-mdx": () => import("./../../../src/pages/revistas/numero-32/how-to-create-an-app-for-daily-tasks-using-microsoft-powerapps.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-32-how-to-create-an-app-for-daily-tasks-using-microsoft-powerapps-mdx" */),
  "component---src-pages-revistas-numero-32-hybrid-connections-azure-webjobs-mdx": () => import("./../../../src/pages/revistas/numero-32/hybrid-connections-azure-webjobs.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-32-hybrid-connections-azure-webjobs-mdx" */),
  "component---src-pages-revistas-numero-32-index-mdx": () => import("./../../../src/pages/revistas/numero-32/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-32-index-mdx" */),
  "component---src-pages-revistas-numero-32-microsoft-dynamics-365-la-nueva-era-de-aplicaciones-mdx": () => import("./../../../src/pages/revistas/numero-32/microsoft-dynamics-365-la-nueva-era-de-aplicaciones.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-32-microsoft-dynamics-365-la-nueva-era-de-aplicaciones-mdx" */),
  "component---src-pages-revistas-numero-32-nueva-experiencia-de-usuario-al-compartir-informacion-en-onedrive-for-business-y-sharepoint-online-mdx": () => import("./../../../src/pages/revistas/numero-32/nueva-experiencia-de-usuario-al-compartir-informacion-en-onedrive-for-business-y-sharepoint-online.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-32-nueva-experiencia-de-usuario-al-compartir-informacion-en-onedrive-for-business-y-sharepoint-online-mdx" */),
  "component---src-pages-revistas-numero-32-onenote-api-en-profundidad-mdx": () => import("./../../../src/pages/revistas/numero-32/onenote-api-en-profundidad.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-32-onenote-api-en-profundidad-mdx" */),
  "component---src-pages-revistas-numero-32-origenes-de-datos-en-power-bi-mdx": () => import("./../../../src/pages/revistas/numero-32/origenes-de-datos-en-power-bi.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-32-origenes-de-datos-en-power-bi-mdx" */),
  "component---src-pages-revistas-numero-32-power-bi-premium-power-bi-report-server-mdx": () => import("./../../../src/pages/revistas/numero-32/power-bi-premium-power-bi-report-server.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-32-power-bi-premium-power-bi-report-server-mdx" */),
  "component---src-pages-revistas-numero-32-que-es-office-365-groups-mdx": () => import("./../../../src/pages/revistas/numero-32/que-es-office-365-groups.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-32-que-es-office-365-groups-mdx" */),
  "component---src-pages-revistas-numero-32-search-as-a-service-mdx": () => import("./../../../src/pages/revistas/numero-32/search-as-a-service.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-32-search-as-a-service-mdx" */),
  "component---src-pages-revistas-numero-32-sharepoint-framework-introduccion-al-toolchain-mdx": () => import("./../../../src/pages/revistas/numero-32/sharepoint-framework-introduccion-al-toolchain.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-32-sharepoint-framework-introduccion-al-toolchain-mdx" */),
  "component---src-pages-revistas-numero-32-sharepoint-y-azure-azure-sql-mdx": () => import("./../../../src/pages/revistas/numero-32/sharepoint-y-azure-azure-sql.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-32-sharepoint-y-azure-azure-sql-mdx" */),
  "component---src-pages-revistas-numero-33-azure-information-protection-de-la-a-a-la-z-mdx": () => import("./../../../src/pages/revistas/numero-33/azure-information-protection-de-la-a-a-la-z.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-33-azure-information-protection-de-la-a-a-la-z-mdx" */),
  "component---src-pages-revistas-numero-33-conectar-flow-con-los-datos-en-la-tierra-mdx": () => import("./../../../src/pages/revistas/numero-33/conectar-flow-con-los-datos-en-la-tierra.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-33-conectar-flow-con-los-datos-en-la-tierra-mdx" */),
  "component---src-pages-revistas-numero-33-entrevista-a-luis-manez-mdx": () => import("./../../../src/pages/revistas/numero-33/entrevista-a-luis-manez.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-33-entrevista-a-luis-manez-mdx" */),
  "component---src-pages-revistas-numero-33-herramientas-estar-al-dia-office-365-mdx": () => import("./../../../src/pages/revistas/numero-33/herramientas-estar-al-dia-office-365.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-33-herramientas-estar-al-dia-office-365-mdx" */),
  "component---src-pages-revistas-numero-33-how-to-use-your-microsoft-powerapps-on-your-devices-mdx": () => import("./../../../src/pages/revistas/numero-33/how-to-use-your-microsoft-powerapps-on-your-devices.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-33-how-to-use-your-microsoft-powerapps-on-your-devices-mdx" */),
  "component---src-pages-revistas-numero-33-index-mdx": () => import("./../../../src/pages/revistas/numero-33/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-33-index-mdx" */),
  "component---src-pages-revistas-numero-33-net-conf-uy-v-2017-mdx": () => import("./../../../src/pages/revistas/numero-33/net-conf-uy-v2017.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-33-net-conf-uy-v-2017-mdx" */),
  "component---src-pages-revistas-numero-33-power-bi-premium-es-para-mi-mdx": () => import("./../../../src/pages/revistas/numero-33/power-bi-premium-es-para-mi.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-33-power-bi-premium-es-para-mi-mdx" */),
  "component---src-pages-revistas-numero-33-provisionando-modern-pages-y-webparts-usando-pnp-mdx": () => import("./../../../src/pages/revistas/numero-33/provisionando-modern-pages-y-webparts-usando-pnp.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-33-provisionando-modern-pages-y-webparts-usando-pnp-mdx" */),
  "component---src-pages-revistas-numero-33-serverless-para-office-365-mdx": () => import("./../../../src/pages/revistas/numero-33/serverless-para-office-365.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-33-serverless-para-office-365-mdx" */),
  "component---src-pages-revistas-numero-33-sharepoint-y-azure-key-vault-mdx": () => import("./../../../src/pages/revistas/numero-33/sharepoint-y-azure-key-vault.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-33-sharepoint-y-azure-key-vault-mdx" */),
  "component---src-pages-revistas-numero-33-spfx-extensions-personaliza-a-sharepoint-online-mdx": () => import("./../../../src/pages/revistas/numero-33/spfx-extensions-personaliza-a-sharepoint-online.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-33-spfx-extensions-personaliza-a-sharepoint-online-mdx" */),
  "component---src-pages-revistas-numero-33-traductor-en-skype-for-business-usando-azure-cognitive-services-mdx": () => import("./../../../src/pages/revistas/numero-33/traductor-en-skype-for-business-usando-azure-cognitive-services.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-33-traductor-en-skype-for-business-usando-azure-cognitive-services-mdx" */),
  "component---src-pages-revistas-numero-33-usando-cosmos-db-table-api-mdx": () => import("./../../../src/pages/revistas/numero-33/usando-cosmos-db-table-api.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-33-usando-cosmos-db-table-api-mdx" */),
  "component---src-pages-revistas-numero-34-administrador-secundario-a-odfb-mdx": () => import("./../../../src/pages/revistas/numero-34/administrador-secundario-a-odfb.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-34-administrador-secundario-a-odfb-mdx" */),
  "component---src-pages-revistas-numero-34-analisis-libro-windows-server-2016-mdx": () => import("./../../../src/pages/revistas/numero-34/analisis-libro-windows-server-2016.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-34-analisis-libro-windows-server-2016-mdx" */),
  "component---src-pages-revistas-numero-34-entrevista-a-vladimir-medina-mdx": () => import("./../../../src/pages/revistas/numero-34/entrevista-a-vladimir-medina.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-34-entrevista-a-vladimir-medina-mdx" */),
  "component---src-pages-revistas-numero-34-haciendo-uso-de-los-cognitive-services-desde-spfx-mdx": () => import("./../../../src/pages/revistas/numero-34/haciendo-uso-de-los-cognitive-services-desde-spfx.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-34-haciendo-uso-de-los-cognitive-services-desde-spfx-mdx" */),
  "component---src-pages-revistas-numero-34-index-mdx": () => import("./../../../src/pages/revistas/numero-34/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-34-index-mdx" */),
  "component---src-pages-revistas-numero-34-instalacion-de-scom-2016-parte-1-mdx": () => import("./../../../src/pages/revistas/numero-34/instalacion-de-scom-2016-parte-1.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-34-instalacion-de-scom-2016-parte-1-mdx" */),
  "component---src-pages-revistas-numero-34-integrando-un-sistema-ia-de-ayuda-con-el-servicio-qna-maker-en-ms-access-mdx": () => import("./../../../src/pages/revistas/numero-34/integrando-un-sistema-ia-de-ayuda-con-el-servicio-qna-maker-en-ms-access.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-34-integrando-un-sistema-ia-de-ayuda-con-el-servicio-qna-maker-en-ms-access-mdx" */),
  "component---src-pages-revistas-numero-34-patrones-de-diseoo-en-typescript-aplicados-a-sharepoint-framework-factory-method-mdx": () => import("./../../../src/pages/revistas/numero-34/patrones-de-diseoo-en-typescript-aplicados-a-sharepoint-framework-–-factory-method.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-34-patrones-de-diseoo-en-typescript-aplicados-a-sharepoint-framework-factory-method-mdx" */),
  "component---src-pages-revistas-numero-34-que-listo-es-mi-sharepoint-mdx": () => import("./../../../src/pages/revistas/numero-34/que-listo-es-mi-sharepoint.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-34-que-listo-es-mi-sharepoint-mdx" */),
  "component---src-pages-revistas-numero-34-seguridad-en-azure-ad-mdx": () => import("./../../../src/pages/revistas/numero-34/seguridad-en-azure-ad.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-34-seguridad-en-azure-ad-mdx" */),
  "component---src-pages-revistas-numero-34-sharepoint-y-azure-el-azure-text-analytics-api-mdx": () => import("./../../../src/pages/revistas/numero-34/sharepoint-y-azure-el-azure-text-analytics-api.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-34-sharepoint-y-azure-el-azure-text-analytics-api-mdx" */),
  "component---src-pages-revistas-numero-34-skype-for-business-ethical-wall-mdx": () => import("./../../../src/pages/revistas/numero-34/skype-for-business-ethical-wall.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-34-skype-for-business-ethical-wall-mdx" */),
  "component---src-pages-revistas-numero-34-spfx-alm-api-mdx": () => import("./../../../src/pages/revistas/numero-34/spfx-alm-api.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-34-spfx-alm-api-mdx" */),
  "component---src-pages-revistas-numero-34-usando-cosmos-db-gremlin-graph-api-mdx": () => import("./../../../src/pages/revistas/numero-34/usando-cosmos-db-gremlin-graph-api.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-34-usando-cosmos-db-gremlin-graph-api-mdx" */),
  "component---src-pages-revistas-numero-35-entrevista-compartimoss-mdx": () => import("./../../../src/pages/revistas/numero-35/entrevista-compartimoss.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-35-entrevista-compartimoss-mdx" */),
  "component---src-pages-revistas-numero-35-gdpr-microsoft-office-365-introduccion-mdx": () => import("./../../../src/pages/revistas/numero-35/gdpr-microsoft-office-365-introduccion.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-35-gdpr-microsoft-office-365-introduccion-mdx" */),
  "component---src-pages-revistas-numero-35-haciendo-uso-de-los-cognitive-services-desde-spfx-text-analytics-api-mdx": () => import("./../../../src/pages/revistas/numero-35/haciendo-uso-de-los-cognitive-services-desde-spfx-–-text-analytics-api.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-35-haciendo-uso-de-los-cognitive-services-desde-spfx-text-analytics-api-mdx" */),
  "component---src-pages-revistas-numero-35-implementando-powershell-con-power-bi-mdx": () => import("./../../../src/pages/revistas/numero-35/implementando-powershell-con-power-bi.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-35-implementando-powershell-con-power-bi-mdx" */),
  "component---src-pages-revistas-numero-35-index-mdx": () => import("./../../../src/pages/revistas/numero-35/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-35-index-mdx" */),
  "component---src-pages-revistas-numero-35-introduccion-a-azure-durable-functions-mdx": () => import("./../../../src/pages/revistas/numero-35/introduccion-a-azure-durable-functions.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-35-introduccion-a-azure-durable-functions-mdx" */),
  "component---src-pages-revistas-numero-35-introduccion-a-powershell-para-microsoft-teams-mdx": () => import("./../../../src/pages/revistas/numero-35/introduccion-a-powershell-para-microsoft-teams.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-35-introduccion-a-powershell-para-microsoft-teams-mdx" */),
  "component---src-pages-revistas-numero-35-patrones-de-diseno-de-typescript-aplicados-a-sharepoint-framework-contructor-mdx": () => import("./../../../src/pages/revistas/numero-35/patrones-de-diseno-de-typescript-aplicados-a-sharepoint-framework-contructor.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-35-patrones-de-diseno-de-typescript-aplicados-a-sharepoint-framework-contructor-mdx" */),
  "component---src-pages-revistas-numero-35-reactjs-parte-i-mdx": () => import("./../../../src/pages/revistas/numero-35/reactjs-parte-i.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-35-reactjs-parte-i-mdx" */),
  "component---src-pages-revistas-numero-35-setup-google-analytics-on-modern-sharepoint-using-spfx-extensions-mdx": () => import("./../../../src/pages/revistas/numero-35/setup-google-analytics-on-modern-sharepoint-using-spfx-extensions.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-35-setup-google-analytics-on-modern-sharepoint-using-spfx-extensions-mdx" */),
  "component---src-pages-revistas-numero-35-sharepoint-y-azure-el-microsoft-translator-api-mdx": () => import("./../../../src/pages/revistas/numero-35/sharepoint-y-azure-el-microsoft-translator-api.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-35-sharepoint-y-azure-el-microsoft-translator-api-mdx" */),
  "component---src-pages-revistas-numero-35-skype-ha-muerto-viva-ms-teams-mdx": () => import("./../../../src/pages/revistas/numero-35/skype-ha-muerto-viva-ms-teams.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-35-skype-ha-muerto-viva-ms-teams-mdx" */),
  "component---src-pages-revistas-numero-35-trigger-serverless-en-una-bbdd-no-sql-en-azure-mdx": () => import("./../../../src/pages/revistas/numero-35/trigger-serverless-en-una-bbdd-no-sql-en-azure.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-35-trigger-serverless-en-una-bbdd-no-sql-en-azure-mdx" */),
  "component---src-pages-revistas-numero-36-apply-a-consistent-branding-to-all-sharepoint-sites-mdx": () => import("./../../../src/pages/revistas/numero-36/apply-a-consistent-branding-to-all-sharepoint-sites.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-36-apply-a-consistent-branding-to-all-sharepoint-sites-mdx" */),
  "component---src-pages-revistas-numero-36-azure-ad-b-2-c-mdx": () => import("./../../../src/pages/revistas/numero-36/azure-ad-b2c.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-36-azure-ad-b-2-c-mdx" */),
  "component---src-pages-revistas-numero-36-contenedores-en-azure-mdx": () => import("./../../../src/pages/revistas/numero-36/contenedores-en-azure.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-36-contenedores-en-azure-mdx" */),
  "component---src-pages-revistas-numero-36-entrevista-a-robert-bermejo-mdx": () => import("./../../../src/pages/revistas/numero-36/entrevista-a-robert-bermejo.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-36-entrevista-a-robert-bermejo-mdx" */),
  "component---src-pages-revistas-numero-36-eventos-real-time-con-azure-signalr-en-asp-net-core-mdx": () => import("./../../../src/pages/revistas/numero-36/eventos-real-time-con-azure-signalr-en-asp-net-core.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-36-eventos-real-time-con-azure-signalr-en-asp-net-core-mdx" */),
  "component---src-pages-revistas-numero-36-index-mdx": () => import("./../../../src/pages/revistas/numero-36/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-36-index-mdx" */),
  "component---src-pages-revistas-numero-36-la-magia-de-crear-chatbots-sin-abrir-visual-studio-gracias-a-formflow-mdx": () => import("./../../../src/pages/revistas/numero-36/la-magia-de-crear-chatbots-sin-abrir-visual-studio-gracias-a-formflow.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-36-la-magia-de-crear-chatbots-sin-abrir-visual-studio-gracias-a-formflow-mdx" */),
  "component---src-pages-revistas-numero-36-microsoft-graph-api-extensions-mdx": () => import("./../../../src/pages/revistas/numero-36/microsoft-graph-api-extensions.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-36-microsoft-graph-api-extensions-mdx" */),
  "component---src-pages-revistas-numero-36-microsoft-identity-manager-2016-mdx": () => import("./../../../src/pages/revistas/numero-36/microsoft-identity-manager-2016.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-36-microsoft-identity-manager-2016-mdx" */),
  "component---src-pages-revistas-numero-36-nuevo-centro-de-administracion-microsoft-teams-y-skype-for-business-mdx": () => import("./../../../src/pages/revistas/numero-36/nuevo-centro-de-administracion-microsoft-teams-y-skype-for-business.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-36-nuevo-centro-de-administracion-microsoft-teams-y-skype-for-business-mdx" */),
  "component---src-pages-revistas-numero-36-reactjs-parte-i-i-mdx": () => import("./../../../src/pages/revistas/numero-36/reactjs-parte-iI.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-36-reactjs-parte-i-i-mdx" */),
  "component---src-pages-revistas-numero-36-sharepoint-y-azure-el-microsoft-bing-search-api-mdx": () => import("./../../../src/pages/revistas/numero-36/sharepoint-y-azure-el-microsoft-bing-search-api.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-36-sharepoint-y-azure-el-microsoft-bing-search-api-mdx" */),
  "component---src-pages-revistas-numero-37-apply-spfx-extensions-to-sharepoint-hub-sites-using-pnp-powershell-mdx": () => import("./../../../src/pages/revistas/numero-37/apply-spfx-extensions-to-sharepoint-hub-sites-using-pnp-powershell.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-37-apply-spfx-extensions-to-sharepoint-hub-sites-using-pnp-powershell-mdx" */),
  "component---src-pages-revistas-numero-37-asegurando-aplicaciones-react-con-azure-ad-mdx": () => import("./../../../src/pages/revistas/numero-37/asegurando-aplicaciones-react-con-azure-ad.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-37-asegurando-aplicaciones-react-con-azure-ad-mdx" */),
  "component---src-pages-revistas-numero-37-autenticando-xamarin-forms-con-azure-ad-mdx": () => import("./../../../src/pages/revistas/numero-37/autenticando-xamarin-forms-con-azure-ad.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-37-autenticando-xamarin-forms-con-azure-ad-mdx" */),
  "component---src-pages-revistas-numero-37-azure-management-groups-mdx": () => import("./../../../src/pages/revistas/numero-37/azure-management-groups.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-37-azure-management-groups-mdx" */),
  "component---src-pages-revistas-numero-37-desarrollando-aplicaciones-de-big-data-con-cloudera-en-azure-mdx": () => import("./../../../src/pages/revistas/numero-37/desarrollando-aplicaciones-de-big-data-con-cloudera-en-azure.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-37-desarrollando-aplicaciones-de-big-data-con-cloudera-en-azure-mdx" */),
  "component---src-pages-revistas-numero-37-entrevista-a-sergio-hernandez-mdx": () => import("./../../../src/pages/revistas/numero-37/entrevista-a-sergio-hernandez.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-37-entrevista-a-sergio-hernandez-mdx" */),
  "component---src-pages-revistas-numero-37-index-mdx": () => import("./../../../src/pages/revistas/numero-37/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-37-index-mdx" */),
  "component---src-pages-revistas-numero-37-indexando-todo-lo-que-necesites-con-azure-search-mdx": () => import("./../../../src/pages/revistas/numero-37/indexando-todo-lo-que-necesites-con-azure-search.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-37-indexando-todo-lo-que-necesites-con-azure-search-mdx" */),
  "component---src-pages-revistas-numero-37-instalacion-de-scom-2016-para-la-monitorizacion-de-nuestra-infraestructura-parte-2-mdx": () => import("./../../../src/pages/revistas/numero-37/instalacion-de-scom-2016-para-la-monitorizacion-de-nuestra-infraestructura-parte-2.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-37-instalacion-de-scom-2016-para-la-monitorizacion-de-nuestra-infraestructura-parte-2-mdx" */),
  "component---src-pages-revistas-numero-37-modernizando-sitios-clasicos-de-sharepoint-online-con-grupos-de-office-365-mdx": () => import("./../../../src/pages/revistas/numero-37/modernizando-sitios-clasicos-de-sharepoint-online-con-grupos-de-office-365.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-37-modernizando-sitios-clasicos-de-sharepoint-online-con-grupos-de-office-365-mdx" */),
  "component---src-pages-revistas-numero-37-multi-idioma-con-sharepoint-framework-mdx": () => import("./../../../src/pages/revistas/numero-37/multi-idioma-con-sharepoint-framework.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-37-multi-idioma-con-sharepoint-framework-mdx" */),
  "component---src-pages-revistas-numero-37-sfpx-dynamic-data-mdx": () => import("./../../../src/pages/revistas/numero-37/sfpx-dynamic-data.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-37-sfpx-dynamic-data-mdx" */),
  "component---src-pages-revistas-numero-37-usando-microsoft-graph-api-schema-extensions-desde-spfx-mdx": () => import("./../../../src/pages/revistas/numero-37/usando-microsoft-graph-api-schema-extensions-desde-spfx.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-37-usando-microsoft-graph-api-schema-extensions-desde-spfx-mdx" */),
  "component---src-pages-revistas-numero-38-azure-cognitive-services-en-un-contenedor-mdx": () => import("./../../../src/pages/revistas/numero-38/azure-cognitive-services-en-un-contenedor.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-38-azure-cognitive-services-en-un-contenedor-mdx" */),
  "component---src-pages-revistas-numero-38-data-lake-analitycs-y-u-sql-mdx": () => import("./../../../src/pages/revistas/numero-38/data-lake-analitycs-y-u-sql.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-38-data-lake-analitycs-y-u-sql-mdx" */),
  "component---src-pages-revistas-numero-38-enable-custom-script-on-modern-sharepoint-sites-to-recover-your-favourite-functionalities-mdx": () => import("./../../../src/pages/revistas/numero-38/enable-custom-script-on-modern-sharepoint-sites-to-recover-your-favourite-functionalities.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-38-enable-custom-script-on-modern-sharepoint-sites-to-recover-your-favourite-functionalities-mdx" */),
  "component---src-pages-revistas-numero-38-entrevista-a-encamina-mdx": () => import("./../../../src/pages/revistas/numero-38/entrevista-a-encamina.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-38-entrevista-a-encamina-mdx" */),
  "component---src-pages-revistas-numero-38-entrevista-a-rodolfo-castro-mdx": () => import("./../../../src/pages/revistas/numero-38/entrevista-a-rodolfo-castro.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-38-entrevista-a-rodolfo-castro-mdx" */),
  "component---src-pages-revistas-numero-38-get-cslatam-mdx": () => import("./../../../src/pages/revistas/numero-38/get-cslatam.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-38-get-cslatam-mdx" */),
  "component---src-pages-revistas-numero-38-hub-sites-en-office-365-acceso-via-rest-mdx": () => import("./../../../src/pages/revistas/numero-38/hub-sites-en-office-365-acceso-via-rest.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-38-hub-sites-en-office-365-acceso-via-rest-mdx" */),
  "component---src-pages-revistas-numero-38-index-mdx": () => import("./../../../src/pages/revistas/numero-38/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-38-index-mdx" */),
  "component---src-pages-revistas-numero-38-introduccion-a-office-365-management-activity-api-mdx": () => import("./../../../src/pages/revistas/numero-38/introduccion-a-office-365-management-activity-api.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-38-introduccion-a-office-365-management-activity-api-mdx" */),
  "component---src-pages-revistas-numero-38-microsoft-teams-y-sharepoint-online-el-matrimonio-perfecto-mdx": () => import("./../../../src/pages/revistas/numero-38/microsoft-teams-y-sharepoint-online-el-matrimonio-perfecto.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-38-microsoft-teams-y-sharepoint-online-el-matrimonio-perfecto-mdx" */),
  "component---src-pages-revistas-numero-38-overview-a-azure-batch-ai-mdx": () => import("./../../../src/pages/revistas/numero-38/overview-a-azure-batch-ai.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-38-overview-a-azure-batch-ai-mdx" */),
  "component---src-pages-revistas-numero-38-sharepoint-framework-1-7-0-que-hay-de-nuevo-mdx": () => import("./../../../src/pages/revistas/numero-38/sharepoint-framework-1-7-0-que-hay-de-nuevo.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-38-sharepoint-framework-1-7-0-que-hay-de-nuevo-mdx" */),
  "component---src-pages-revistas-numero-38-sharepoint-y-azure-reconocimiento-de-imagenes-mdx": () => import("./../../../src/pages/revistas/numero-38/sharepoint-y-azure-reconocimiento-de-imagenes.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-38-sharepoint-y-azure-reconocimiento-de-imagenes-mdx" */),
  "component---src-pages-revistas-numero-38-spfx-performance-mdx": () => import("./../../../src/pages/revistas/numero-38/spfx-performance.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-38-spfx-performance-mdx" */),
  "component---src-pages-revistas-numero-38-trae-los-datos-de-office-365-a-tus-aplicaciones-con-microsoft-graph-data-connect-mdx": () => import("./../../../src/pages/revistas/numero-38/trae-los-datos-de-office-365-a-tus-aplicaciones-con-microsoft-graph-data-connect.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-38-trae-los-datos-de-office-365-a-tus-aplicaciones-con-microsoft-graph-data-connect-mdx" */),
  "component---src-pages-revistas-numero-39-adios-exchange-um-online-mdx": () => import("./../../../src/pages/revistas/numero-39/adios-exchange-um-online.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-39-adios-exchange-um-online-mdx" */),
  "component---src-pages-revistas-numero-39-analisis-de-logs-uls-usando-elasticstack-mdx": () => import("./../../../src/pages/revistas/numero-39/analisis-de-logs-uls-usando-elasticstack.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-39-analisis-de-logs-uls-usando-elasticstack-mdx" */),
  "component---src-pages-revistas-numero-39-asistentes-con-net-parte-i-mdx": () => import("./../../../src/pages/revistas/numero-39/asistentes-con-net-parte-i.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-39-asistentes-con-net-parte-i-mdx" */),
  "component---src-pages-revistas-numero-39-azure-ad-quien-te-ha-visto-y-quien-te-ve-mdx": () => import("./../../../src/pages/revistas/numero-39/azure-ad-quien-te-ha-visto-y-quien-te-ve.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-39-azure-ad-quien-te-ha-visto-y-quien-te-ve-mdx" */),
  "component---src-pages-revistas-numero-39-azure-dev-spaces-desarrollando-con-kubernetes-y-en-equipo-mdx": () => import("./../../../src/pages/revistas/numero-39/azure-dev-spaces-desarrollando-con-kubernetes-y-en-equipo.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-39-azure-dev-spaces-desarrollando-con-kubernetes-y-en-equipo-mdx" */),
  "component---src-pages-revistas-numero-39-convert-classic-root-sites-to-modern-sharepoint-mdx": () => import("./../../../src/pages/revistas/numero-39/convert-classic-root-sites-to-modern-sharepoint.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-39-convert-classic-root-sites-to-modern-sharepoint-mdx" */),
  "component---src-pages-revistas-numero-39-entrevista-a-gaston-cruz-mdx": () => import("./../../../src/pages/revistas/numero-39/entrevista-a-gaston-cruz.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-39-entrevista-a-gaston-cruz-mdx" */),
  "component---src-pages-revistas-numero-39-entrevista-a-kwizcom-mdx": () => import("./../../../src/pages/revistas/numero-39/entrevista-a-kwizcom.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-39-entrevista-a-kwizcom-mdx" */),
  "component---src-pages-revistas-numero-39-guardar-firma-desde-la-entrada-de-escritura-manual-de-powerapps-en-una-lista-de-sharepoint-mdx": () => import("./../../../src/pages/revistas/numero-39/guardar-firma-desde-la-entrada-de-escritura-manual-de-powerapps-en-una-lista-de-sharepoint.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-39-guardar-firma-desde-la-entrada-de-escritura-manual-de-powerapps-en-una-lista-de-sharepoint-mdx" */),
  "component---src-pages-revistas-numero-39-index-mdx": () => import("./../../../src/pages/revistas/numero-39/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-39-index-mdx" */),
  "component---src-pages-revistas-numero-39-ms-teams-como-autenticar-nuestros-desarrollos-mdx": () => import("./../../../src/pages/revistas/numero-39/ms-teams-como-autenticar-nuestros-desarrollos.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-39-ms-teams-como-autenticar-nuestros-desarrollos-mdx" */),
  "component---src-pages-revistas-numero-39-msal-introduccion-y-ejemplo-de-uso-para-llamar-a-graph-api-mdx": () => import("./../../../src/pages/revistas/numero-39/msal-introduccion-y-ejemplo-de-uso-para-llamar-a-graph-api.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-39-msal-introduccion-y-ejemplo-de-uso-para-llamar-a-graph-api-mdx" */),
  "component---src-pages-revistas-numero-39-nuevas-opciones-de-personalizacion-en-sitios-modernos-de-sharepoint-online-mdx": () => import("./../../../src/pages/revistas/numero-39/nuevas-opciones-de-personalizacion-en-sitios-modernos-de-sharepoint-online.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-39-nuevas-opciones-de-personalizacion-en-sitios-modernos-de-sharepoint-online-mdx" */),
  "component---src-pages-revistas-numero-39-sharepoint-y-azure-azure-automation-mdx": () => import("./../../../src/pages/revistas/numero-39/sharepoint-y-azure-azure-automation.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-39-sharepoint-y-azure-azure-automation-mdx" */),
  "component---src-pages-revistas-numero-39-sitios-web-estaticos-en-azure-storage-mdx": () => import("./../../../src/pages/revistas/numero-39/sitios-web-estaticos-en-azure-storage.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-39-sitios-web-estaticos-en-azure-storage-mdx" */),
  "component---src-pages-revistas-numero-39-trabajando-con-disenos-de-sitio-en-sharepoint-online-mdx": () => import("./../../../src/pages/revistas/numero-39/trabajando-con-disenos-de-sitio-en-sharepoint-online.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-39-trabajando-con-disenos-de-sitio-en-sharepoint-online-mdx" */),
  "component---src-pages-revistas-numero-4-index-mdx": () => import("./../../../src/pages/revistas/numero-4/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-4-index-mdx" */),
  "component---src-pages-revistas-numero-40-10-consejos-para-mejorar-la-adopcion-y-gobernanza-de-microsoft-teams-mdx": () => import("./../../../src/pages/revistas/numero-40/10-consejos-para-mejorar-la-adopcion-y-gobernanza-de-microsoft-teams.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-40-10-consejos-para-mejorar-la-adopcion-y-gobernanza-de-microsoft-teams-mdx" */),
  "component---src-pages-revistas-numero-40-add-a-user-as-an-admin-on-all-associated-sharepoint-sites-on-a-hub-mdx": () => import("./../../../src/pages/revistas/numero-40/add-a-user-as-an-admin-on-all-associated-sharepoint-sites-on-a-hub.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-40-add-a-user-as-an-admin-on-all-associated-sharepoint-sites-on-a-hub-mdx" */),
  "component---src-pages-revistas-numero-40-asistentes-con-net-parte-ii-gactions-mdx": () => import("./../../../src/pages/revistas/numero-40/asistentes-con-net-parte-ii-gactions.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-40-asistentes-con-net-parte-ii-gactions-mdx" */),
  "component---src-pages-revistas-numero-40-azure-app-configuration-mdx": () => import("./../../../src/pages/revistas/numero-40/azure-app-configuration.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-40-azure-app-configuration-mdx" */),
  "component---src-pages-revistas-numero-40-azure-digital-twins-mdx": () => import("./../../../src/pages/revistas/numero-40/azure-digital-twins.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-40-azure-digital-twins-mdx" */),
  "component---src-pages-revistas-numero-40-azure-event-grid-el-pegamento-de-nuestras-arquitecturas-orientadas-a-eventos-mdx": () => import("./../../../src/pages/revistas/numero-40/azure-event-grid-el-pegamento-de-nuestras-arquitecturas-orientadas-a-eventos.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-40-azure-event-grid-el-pegamento-de-nuestras-arquitecturas-orientadas-a-eventos-mdx" */),
  "component---src-pages-revistas-numero-40-como-disenar-apps-multitenant-en-azure-mdx": () => import("./../../../src/pages/revistas/numero-40/como-disenar-apps-multitenant-en-azure.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-40-como-disenar-apps-multitenant-en-azure-mdx" */),
  "component---src-pages-revistas-numero-40-cosmosdb-integration-testing-con-azuredevops-mdx": () => import("./../../../src/pages/revistas/numero-40/cosmosdb-integration-testing-con-azuredevops.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-40-cosmosdb-integration-testing-con-azuredevops-mdx" */),
  "component---src-pages-revistas-numero-40-dataflows-desde-lo-basico-y-mas-alla-mdx": () => import("./../../../src/pages/revistas/numero-40/dataflows-desde-lo-basico-y-mas-alla.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-40-dataflows-desde-lo-basico-y-mas-alla-mdx" */),
  "component---src-pages-revistas-numero-40-entrevista-a-david-rivera-mdx": () => import("./../../../src/pages/revistas/numero-40/entrevista-a-david-rivera.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-40-entrevista-a-david-rivera-mdx" */),
  "component---src-pages-revistas-numero-40-homenaje-a-ricardo-munoz-mdx": () => import("./../../../src/pages/revistas/numero-40/homenaje-a-ricardo-munoz.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-40-homenaje-a-ricardo-munoz-mdx" */),
  "component---src-pages-revistas-numero-40-index-mdx": () => import("./../../../src/pages/revistas/numero-40/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-40-index-mdx" */),
  "component---src-pages-revistas-numero-40-library-components-en-sharepoint-framework-1-8-mdx": () => import("./../../../src/pages/revistas/numero-40/library-components-en-sharepoint-framework-1-8.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-40-library-components-en-sharepoint-framework-1-8-mdx" */),
  "component---src-pages-revistas-numero-40-microsoft-flow-vs-azure-logic-apps-mdx": () => import("./../../../src/pages/revistas/numero-40/microsoft-flow-vs-azure-logic-apps.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-40-microsoft-flow-vs-azure-logic-apps-mdx" */),
  "component---src-pages-revistas-numero-40-posibilidades-de-recuperacion-frente-a-desastres-en-sharepoint-online-mdx": () => import("./../../../src/pages/revistas/numero-40/posibilidades-de-recuperacion-frente-a-desastres-en-sharepoint-online.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-40-posibilidades-de-recuperacion-frente-a-desastres-en-sharepoint-online-mdx" */),
  "component---src-pages-revistas-numero-40-primeros-pasos-con-azure-sphere-mdx": () => import("./../../../src/pages/revistas/numero-40/primeros-pasos-con-azure-sphere.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-40-primeros-pasos-con-azure-sphere-mdx" */),
  "component---src-pages-revistas-numero-40-run-multiple-instances-of-microsoft-teams-mdx": () => import("./../../../src/pages/revistas/numero-40/run-multiple-instances-of-microsoft-teams.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-40-run-multiple-instances-of-microsoft-teams-mdx" */),
  "component---src-pages-revistas-numero-40-sharepoint-y-azure-crear-servicios-rest-para-sharepoint-con-azure-api-apps-mdx": () => import("./../../../src/pages/revistas/numero-40/sharepoint-y-azure-crear-servicios-rest-para-sharepoint-con-azure-api-apps.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-40-sharepoint-y-azure-crear-servicios-rest-para-sharepoint-con-azure-api-apps-mdx" */),
  "component---src-pages-revistas-numero-41-alarma-1202-mdx": () => import("./../../../src/pages/revistas/numero-41/alarma-1202.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-41-alarma-1202-mdx" */),
  "component---src-pages-revistas-numero-41-catalogo-de-apis-securizado-con-azure-api-management-azure-ad-y-oauth-en-tu-apim-azure-api-management-como-middelware-de-tus-servicios-mdx": () => import("./../../../src/pages/revistas/numero-41/catalogo-de-apis-securizado-con-azure-api-management-azure-ad-y-oauth-en-tu-apim-azure-api-management-como-middelware-de-tus-servicios.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-41-catalogo-de-apis-securizado-con-azure-api-management-azure-ad-y-oauth-en-tu-apim-azure-api-management-como-middelware-de-tus-servicios-mdx" */),
  "component---src-pages-revistas-numero-41-el-nivel-de-acceso-archive-en-azure-mdx": () => import("./../../../src/pages/revistas/numero-41/el-nivel-de-acceso-archive-en-azure.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-41-el-nivel-de-acceso-archive-en-azure-mdx" */),
  "component---src-pages-revistas-numero-41-entrevista-a-juan-ignacio-oller-aznar-mdx": () => import("./../../../src/pages/revistas/numero-41/entrevista-a-juan-ignacio-oller-aznar.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-41-entrevista-a-juan-ignacio-oller-aznar-mdx" */),
  "component---src-pages-revistas-numero-41-entrevista-a-plain-concepts-mdx": () => import("./../../../src/pages/revistas/numero-41/entrevista-a-plain-concepts.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-41-entrevista-a-plain-concepts-mdx" */),
  "component---src-pages-revistas-numero-41-index-mdx": () => import("./../../../src/pages/revistas/numero-41/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-41-index-mdx" */),
  "component---src-pages-revistas-numero-41-introduccion-a-azure-search-mdx": () => import("./../../../src/pages/revistas/numero-41/introduccion-a-azure-search.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-41-introduccion-a-azure-search-mdx" */),
  "component---src-pages-revistas-numero-41-pnp-provisioning-tras-la-magia-del-tokenparser-y-localizando-nuestras-templates-mdx": () => import("./../../../src/pages/revistas/numero-41/pnp-provisioning-tras-la-magia-del-tokenparser-y-localizando-nuestras-templates.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-41-pnp-provisioning-tras-la-magia-del-tokenparser-y-localizando-nuestras-templates-mdx" */),
  "component---src-pages-revistas-numero-41-primeros-pasos-con-azure-resource-graph-mdx": () => import("./../../../src/pages/revistas/numero-41/primeros-pasos-con-azure-resource-graph.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-41-primeros-pasos-con-azure-resource-graph-mdx" */),
  "component---src-pages-revistas-numero-41-spfx-react-hooks-mdx": () => import("./../../../src/pages/revistas/numero-41/spfx-react-hooks.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-41-spfx-react-hooks-mdx" */),
  "component---src-pages-revistas-numero-41-sql-azure-serverless-mdx": () => import("./../../../src/pages/revistas/numero-41/sql-azure-serverless.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-41-sql-azure-serverless-mdx" */),
  "component---src-pages-revistas-numero-41-un-repaso-al-modern-admin-center-de-sharepoint-online-mdx": () => import("./../../../src/pages/revistas/numero-41/un-repaso-al-modern-admin-center-de-sharepoint-online.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-41-un-repaso-al-modern-admin-center-de-sharepoint-online-mdx" */),
  "component---src-pages-revistas-numero-41-un-vistazo-a-microsoft-graph-toolkit-web-components-con-graph-mdx": () => import("./../../../src/pages/revistas/numero-41/un-vistazo-a-microsoft-graph-toolkit-web-components-con-graph.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-41-un-vistazo-a-microsoft-graph-toolkit-web-components-con-graph-mdx" */),
  "component---src-pages-revistas-numero-41-uso-de-funciones-de-azure-con-flow-y-powerapps-part-1-mdx": () => import("./../../../src/pages/revistas/numero-41/uso-de-funciones-de-azure-con-flow-y-powerapps-part-1.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-41-uso-de-funciones-de-azure-con-flow-y-powerapps-part-1-mdx" */),
  "component---src-pages-revistas-numero-42-chatbots-al-rescate-mdx": () => import("./../../../src/pages/revistas/numero-42/chatbots-al-rescate.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-42-chatbots-al-rescate-mdx" */),
  "component---src-pages-revistas-numero-42-como-consumir-apis-securizadas-con-el-azure-active-directory-en-nuestros-desarrollos-de-spfx-mdx": () => import("./../../../src/pages/revistas/numero-42/como-consumir-apis-securizadas-con-el-azure-active-directory-en-nuestros-desarrollos-de-spfx.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-42-como-consumir-apis-securizadas-con-el-azure-active-directory-en-nuestros-desarrollos-de-spfx-mdx" */),
  "component---src-pages-revistas-numero-42-entrevista-a-raona-mdx": () => import("./../../../src/pages/revistas/numero-42/entrevista-a-raona.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-42-entrevista-a-raona-mdx" */),
  "component---src-pages-revistas-numero-42-entrevista-a-rodrigo-diaz-concha-mdx": () => import("./../../../src/pages/revistas/numero-42/entrevista-a-rodrigo-diaz-concha.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-42-entrevista-a-rodrigo-diaz-concha-mdx" */),
  "component---src-pages-revistas-numero-42-index-mdx": () => import("./../../../src/pages/revistas/numero-42/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-42-index-mdx" */),
  "component---src-pages-revistas-numero-42-introduccion-a-azure-synapse-mdx": () => import("./../../../src/pages/revistas/numero-42/introduccion-a-azure-synapse.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-42-introduccion-a-azure-synapse-mdx" */),
  "component---src-pages-revistas-numero-42-la-arquitectura-de-sharepoint-online-mdx": () => import("./../../../src/pages/revistas/numero-42/la-arquitectura-de-sharepoint-online.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-42-la-arquitectura-de-sharepoint-online-mdx" */),
  "component---src-pages-revistas-numero-42-nuevos-planes-de-licenciamiento-de-microsoft-flow-mdx": () => import("./../../../src/pages/revistas/numero-42/nuevos-planes-de-licenciamiento-de-microsoft-flow.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-42-nuevos-planes-de-licenciamiento-de-microsoft-flow-mdx" */),
  "component---src-pages-revistas-numero-42-optimizando-tu-cosmos-db-mejorando-tus-consultas-con-change-feed-mdx": () => import("./../../../src/pages/revistas/numero-42/optimizando-tu-cosmos-db-mejorando-tus-consultas-con-change-feed.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-42-optimizando-tu-cosmos-db-mejorando-tus-consultas-con-change-feed-mdx" */),
  "component---src-pages-revistas-numero-42-productivity-tips-con-microsoft-teams-busqueda-de-informacion-mdx": () => import("./../../../src/pages/revistas/numero-42/productivity-tips-con-microsoft-teams-busqueda-de-informacion.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-42-productivity-tips-con-microsoft-teams-busqueda-de-informacion-mdx" */),
  "component---src-pages-revistas-numero-42-seguridad-en-el-cloud-mdx": () => import("./../../../src/pages/revistas/numero-42/seguridad-en-el-cloud.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-42-seguridad-en-el-cloud-mdx" */),
  "component---src-pages-revistas-numero-42-usando-bing-news-api-desde-spfx-mdx": () => import("./../../../src/pages/revistas/numero-42/usando-bing-news-api-desde-spfx.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-42-usando-bing-news-api-desde-spfx-mdx" */),
  "component---src-pages-revistas-numero-42-uso-de-funciones-de-azure-con-powerautomate-y-powerapps-parte-2-usando-openapi-en-powerautomate-mdx": () => import("./../../../src/pages/revistas/numero-42/uso-de-funciones-de-azure-con-powerautomate-y-powerapps-parte-2-usando-openapi-en-powerautomate.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-42-uso-de-funciones-de-azure-con-powerautomate-y-powerapps-parte-2-usando-openapi-en-powerautomate-mdx" */),
  "component---src-pages-revistas-numero-43-azure-keyvault-docker-mdx": () => import("./../../../src/pages/revistas/numero-43/azure-keyvault-docker.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-43-azure-keyvault-docker-mdx" */),
  "component---src-pages-revistas-numero-43-clasificacion-textos-ai-builder-power-automate-mdx": () => import("./../../../src/pages/revistas/numero-43/clasificacion-textos-ai-builder-power-automate.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-43-clasificacion-textos-ai-builder-power-automate-mdx" */),
  "component---src-pages-revistas-numero-43-configurar-autenticacion-power-virtual-agent-mdx": () => import("./../../../src/pages/revistas/numero-43/configurar-autenticacion-power-virtual-agent.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-43-configurar-autenticacion-power-virtual-agent-mdx" */),
  "component---src-pages-revistas-numero-43-crm-dynamics-365-cds-y-power-apps-mdx": () => import("./../../../src/pages/revistas/numero-43/crm-dynamics-365-cds-y-power-apps.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-43-crm-dynamics-365-cds-y-power-apps-mdx" */),
  "component---src-pages-revistas-numero-43-entrevista-a-imanol-iza-mdx": () => import("./../../../src/pages/revistas/numero-43/entrevista-a-imanol-iza.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-43-entrevista-a-imanol-iza-mdx" */),
  "component---src-pages-revistas-numero-43-entrevista-a-siderys-mdx": () => import("./../../../src/pages/revistas/numero-43/entrevista-a-siderys.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-43-entrevista-a-siderys-mdx" */),
  "component---src-pages-revistas-numero-43-funciones-azure-flow-y-power-apps-parte-3-usando-openapi-en-power-apps-mdx": () => import("./../../../src/pages/revistas/numero-43/funciones-azure-flow-y-power-apps-parte-3-usando-openapi-en-power-apps.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-43-funciones-azure-flow-y-power-apps-parte-3-usando-openapi-en-power-apps-mdx" */),
  "component---src-pages-revistas-numero-43-index-mdx": () => import("./../../../src/pages/revistas/numero-43/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-43-index-mdx" */),
  "component---src-pages-revistas-numero-43-logic-apps-y-http-actions-puedo-hacer-llamadas-async-mdx": () => import("./../../../src/pages/revistas/numero-43/logic-apps-y-http-actions-puedo-hacer-llamadas-async.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-43-logic-apps-y-http-actions-puedo-hacer-llamadas-async-mdx" */),
  "component---src-pages-revistas-numero-43-net-core-seguridad-autenticacion-autorizacion-api-mdx": () => import("./../../../src/pages/revistas/numero-43/net-core-seguridad-autenticacion-autorizacion-api.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-43-net-core-seguridad-autenticacion-autorizacion-api-mdx" */),
  "component---src-pages-revistas-numero-43-productivity-tips-con-microsoft-teams-canales-privados-mdx": () => import("./../../../src/pages/revistas/numero-43/productivity-tips-con-microsoft-teams-canales-privados.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-43-productivity-tips-con-microsoft-teams-canales-privados-mdx" */),
  "component---src-pages-revistas-numero-43-usando-el-nuevo-endpoint-de-presencia-en-ms-graph-api-desde-spfx-mdx": () => import("./../../../src/pages/revistas/numero-43/usando-el-nuevo-endpoint-de-presencia-en-ms-graph-api-desde-spfx.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-43-usando-el-nuevo-endpoint-de-presencia-en-ms-graph-api-desde-spfx-mdx" */),
  "component---src-pages-revistas-numero-44-construyendo-maquinas-de-estados-con-webhook-y-logic-app-mdx": () => import("./../../../src/pages/revistas/numero-44/construyendo-maquinas-de-estados-con-webhook-y-logic-app.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-44-construyendo-maquinas-de-estados-con-webhook-y-logic-app-mdx" */),
  "component---src-pages-revistas-numero-44-cosmosdb-autopilot-mdx": () => import("./../../../src/pages/revistas/numero-44/cosmosdb-autopilot.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-44-cosmosdb-autopilot-mdx" */),
  "component---src-pages-revistas-numero-44-deteccion-de-objetos-con-custom-vision-mdx": () => import("./../../../src/pages/revistas/numero-44/deteccion-de-objetos-con-custom-vision.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-44-deteccion-de-objetos-con-custom-vision-mdx" */),
  "component---src-pages-revistas-numero-44-dimensionando-desescalapp-com-en-azure-mdx": () => import("./../../../src/pages/revistas/numero-44/dimensionando-desescalapp-com-en-azure.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-44-dimensionando-desescalapp-com-en-azure-mdx" */),
  "component---src-pages-revistas-numero-44-el-comic-como-medio-de-ensenanza-de-conceptos-de-programacion-mdx": () => import("./../../../src/pages/revistas/numero-44/el-comic-como-medio-de-ensenanza-de-conceptos-de-programacion.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-44-el-comic-como-medio-de-ensenanza-de-conceptos-de-programacion-mdx" */),
  "component---src-pages-revistas-numero-44-el-learning-pathways-de-microsoft-365-mdx": () => import("./../../../src/pages/revistas/numero-44/el-learning-pathways-de-microsoft-365.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-44-el-learning-pathways-de-microsoft-365-mdx" */),
  "component---src-pages-revistas-numero-44-entrevista-a-intelequia-software-solutions-mdx": () => import("./../../../src/pages/revistas/numero-44/entrevista-a-intelequia-software-solutions.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-44-entrevista-a-intelequia-software-solutions-mdx" */),
  "component---src-pages-revistas-numero-44-entrevista-a-olga-marti-mdx": () => import("./../../../src/pages/revistas/numero-44/entrevista-a-olga-marti.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-44-entrevista-a-olga-marti-mdx" */),
  "component---src-pages-revistas-numero-44-index-mdx": () => import("./../../../src/pages/revistas/numero-44/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-44-index-mdx" */),
  "component---src-pages-revistas-numero-44-introduccion-al-almacen-de-conocimiento-knowledge-store-mdx": () => import("./../../../src/pages/revistas/numero-44/introducción-al-almacen-de-conocimiento-knowledge-store.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-44-introduccion-al-almacen-de-conocimiento-knowledge-store-mdx" */),
  "component---src-pages-revistas-numero-44-introduccion-al-cumplimiento-de-comunicacion-en-microsoft-365-mdx": () => import("./../../../src/pages/revistas/numero-44/introduccion-al-cumplimiento-de-comunicacion-en-microsoft-365.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-44-introduccion-al-cumplimiento-de-comunicacion-en-microsoft-365-mdx" */),
  "component---src-pages-revistas-numero-44-microsoft-graph-toolkit-en-profundidad-mdx": () => import("./../../../src/pages/revistas/numero-44/microsoft-graph-toolkit-en-profundidad.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-44-microsoft-graph-toolkit-en-profundidad-mdx" */),
  "component---src-pages-revistas-numero-44-modern-search-con-pnp-webparts-introduccion-mdx": () => import("./../../../src/pages/revistas/numero-44/modern-search-con-pnp-webparts-introduccion.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-44-modern-search-con-pnp-webparts-introduccion-mdx" */),
  "component---src-pages-revistas-numero-44-procesando-modelos-tabulares-mdx": () => import("./../../../src/pages/revistas/numero-44/procesando-modelos-tabulares.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-44-procesando-modelos-tabulares-mdx" */),
  "component---src-pages-revistas-numero-44-productivity-tips-con-microsoft-teams-ultimas-novedades-en-reuniones-llamadas-y-videollamadas-en-teams-mdx": () => import("./../../../src/pages/revistas/numero-44/productivity-tips-con-microsoft-teams-ultimas-novedades-en-reuniones-llamadas-y-videollamadas-en-teams.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-44-productivity-tips-con-microsoft-teams-ultimas-novedades-en-reuniones-llamadas-y-videollamadas-en-teams-mdx" */),
  "component---src-pages-revistas-numero-44-project-cortex-un-microsoft-365-mucho-mas-inteligente-mdx": () => import("./../../../src/pages/revistas/numero-44/project-cortex-un-microsoft-365-mucho-mas-inteligente.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-44-project-cortex-un-microsoft-365-mucho-mas-inteligente-mdx" */),
  "component---src-pages-revistas-numero-44-uso-de-azure-monitor-mdx": () => import("./../../../src/pages/revistas/numero-44/uso-de-azure-monitor.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-44-uso-de-azure-monitor-mdx" */),
  "component---src-pages-revistas-numero-45-asignacion-de-politicas-en-teams-mdx": () => import("./../../../src/pages/revistas/numero-45/asignacion-de-politicas-en-teams.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-45-asignacion-de-politicas-en-teams-mdx" */),
  "component---src-pages-revistas-numero-45-asp-net-core-con-reactjs-mdx": () => import("./../../../src/pages/revistas/numero-45/asp.net-core-con-reactjs.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-45-asp-net-core-con-reactjs-mdx" */),
  "component---src-pages-revistas-numero-45-asp-net-core-gestionando-cookies-de-autenticacion-server-side-mdx": () => import("./../../../src/pages/revistas/numero-45/asp.net-core-gestionando-cookies-de-autenticacion-server-side.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-45-asp-net-core-gestionando-cookies-de-autenticacion-server-side-mdx" */),
  "component---src-pages-revistas-numero-45-azure-anomaly-detector-mdx": () => import("./../../../src/pages/revistas/numero-45/azure-anomaly-detector.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-45-azure-anomaly-detector-mdx" */),
  "component---src-pages-revistas-numero-45-azure-static-web-apps-que-es-este-nuevo-servicio-mdx": () => import("./../../../src/pages/revistas/numero-45/azure-static-web-apps-que-es-este-nuevo-servicio.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-45-azure-static-web-apps-que-es-este-nuevo-servicio-mdx" */),
  "component---src-pages-revistas-numero-45-entrevista-a-kabel-mdx": () => import("./../../../src/pages/revistas/numero-45/entrevista-a-kabel.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-45-entrevista-a-kabel-mdx" */),
  "component---src-pages-revistas-numero-45-entrevista-a-mayra-badillo-mdx": () => import("./../../../src/pages/revistas/numero-45/entrevista-a-mayra-badillo.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-45-entrevista-a-mayra-badillo-mdx" */),
  "component---src-pages-revistas-numero-45-gestion-de-servidores-externos-mediante-azure-policy-mdx": () => import("./../../../src/pages/revistas/numero-45/gestion-de-servidores-externos-mediante-azure-policy.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-45-gestion-de-servidores-externos-mediante-azure-policy-mdx" */),
  "component---src-pages-revistas-numero-45-herramientas-de-microsoft-para-migrar-a-spo-y-odfb-mdx": () => import("./../../../src/pages/revistas/numero-45/herramientas-de-microsoft-para-migrar-a-spo-y-odfb.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-45-herramientas-de-microsoft-para-migrar-a-spo-y-odfb-mdx" */),
  "component---src-pages-revistas-numero-45-index-mdx": () => import("./../../../src/pages/revistas/numero-45/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-45-index-mdx" */),
  "component---src-pages-revistas-numero-45-integrando-azure-cognitives-services-con-sharepoint-online-mdx": () => import("./../../../src/pages/revistas/numero-45/integrando-azure-cognitives-services-con-sharepoint-online.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-45-integrando-azure-cognitives-services-con-sharepoint-online-mdx" */),
  "component---src-pages-revistas-numero-45-intro-a-xmla-endpoints-de-power-bi-mdx": () => import("./../../../src/pages/revistas/numero-45/intro-a-xmla-endpoints-de-power-bi.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-45-intro-a-xmla-endpoints-de-power-bi-mdx" */),
  "component---src-pages-revistas-numero-45-introduccion-a-azure-sql-mdx": () => import("./../../../src/pages/revistas/numero-45/introduccion-a-azure-sql.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-45-introduccion-a-azure-sql-mdx" */),
  "component---src-pages-revistas-numero-45-jupyter-azure-ml-y-databricks-mdx": () => import("./../../../src/pages/revistas/numero-45/jupyter-azure-ml-y-databricks.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-45-jupyter-azure-ml-y-databricks-mdx" */),
  "component---src-pages-revistas-numero-45-ms-graph-todo-endpoint-mdx": () => import("./../../../src/pages/revistas/numero-45/ms-graph-todo-endpoint.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-45-ms-graph-todo-endpoint-mdx" */),
  "component---src-pages-revistas-numero-45-powerapps-azure-maps-mdx": () => import("./../../../src/pages/revistas/numero-45/powerapps-azure-maps.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-45-powerapps-azure-maps-mdx" */),
  "component---src-pages-revistas-numero-46-ahorrando-codigo-funcionalidades-de-c-para-no-programar-de-mas-mdx": () => import("./../../../src/pages/revistas/numero-46/ahorrando-codigo-funcionalidades-de-c-para-no-programar-de-mas.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-46-ahorrando-codigo-funcionalidades-de-c-para-no-programar-de-mas-mdx" */),
  "component---src-pages-revistas-numero-46-de-azure-table-storage-a-lists-usando-logic-apps-mdx": () => import("./../../../src/pages/revistas/numero-46/de-azure-table-storage-a-lists-usando-logic-apps.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-46-de-azure-table-storage-a-lists-usando-logic-apps-mdx" */),
  "component---src-pages-revistas-numero-46-desarrollando-ms-teams-messaging-extensions-desde-spfx-mdx": () => import("./../../../src/pages/revistas/numero-46/desarrollando-ms-teams-messaging-extensions-desde-spfx.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-46-desarrollando-ms-teams-messaging-extensions-desde-spfx-mdx" */),
  "component---src-pages-revistas-numero-46-entrevista-aura-mdx": () => import("./../../../src/pages/revistas/numero-46/entrevista-aura.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-46-entrevista-aura-mdx" */),
  "component---src-pages-revistas-numero-46-entrevista-jose-manuel-alarcon-mdx": () => import("./../../../src/pages/revistas/numero-46/entrevista-jose-manuel-alarcon.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-46-entrevista-jose-manuel-alarcon-mdx" */),
  "component---src-pages-revistas-numero-46-form-recognizer-azure-functions-mdx": () => import("./../../../src/pages/revistas/numero-46/form-recognizer_azure-functions.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-46-form-recognizer-azure-functions-mdx" */),
  "component---src-pages-revistas-numero-46-index-mdx": () => import("./../../../src/pages/revistas/numero-46/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-46-index-mdx" */),
  "component---src-pages-revistas-numero-46-intro-a-synapse-analytics-mdx": () => import("./../../../src/pages/revistas/numero-46/intro-a-synapse-analytics.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-46-intro-a-synapse-analytics-mdx" */),
  "component---src-pages-revistas-numero-46-microsoft-bookings-gestion-sencilla-de-citas-en-escenarios-de-remote-work-mdx": () => import("./../../../src/pages/revistas/numero-46/microsoft-bookings-gestion-sencilla-de-citas-en-escenarios-de-remote-work.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-46-microsoft-bookings-gestion-sencilla-de-citas-en-escenarios-de-remote-work-mdx" */),
  "component---src-pages-revistas-numero-46-network-watcher-mdx": () => import("./../../../src/pages/revistas/numero-46/network-watcher.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-46-network-watcher-mdx" */),
  "component---src-pages-revistas-numero-46-pon-un-bot-en-tu-vida-y-en-microsoft-teams-mdx": () => import("./../../../src/pages/revistas/numero-46/pon-un-bot-en-tu-vida-y-en-microsoft-teams.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-46-pon-un-bot-en-tu-vida-y-en-microsoft-teams-mdx" */),
  "component---src-pages-revistas-numero-46-power-virtual-agents-microsoft-teams-mdx": () => import("./../../../src/pages/revistas/numero-46/power_virtual_agents_microsoft_teams.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-46-power-virtual-agents-microsoft-teams-mdx" */),
  "component---src-pages-revistas-numero-46-secret-management-powershell-mdx": () => import("./../../../src/pages/revistas/numero-46/secret-management-powershell.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-46-secret-management-powershell-mdx" */),
  "component---src-pages-revistas-numero-46-sql-server-everywhere-mdx": () => import("./../../../src/pages/revistas/numero-46/sql-server-everywhere.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-46-sql-server-everywhere-mdx" */),
  "component---src-pages-revistas-numero-47-anatomia-de-una-adaptive-card-mdx": () => import("./../../../src/pages/revistas/numero-47/anatomia-de-una-adaptive-card.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-47-anatomia-de-una-adaptive-card-mdx" */),
  "component---src-pages-revistas-numero-47-aplicacion-de-microsoft-lists-en-microsoft-365-o-listas-de-sharepoint-v-2-mdx": () => import("./../../../src/pages/revistas/numero-47/aplicacion-de-microsoft-lists-en-microsoft-365-o-listas-de-sharepoint-v2.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-47-aplicacion-de-microsoft-lists-en-microsoft-365-o-listas-de-sharepoint-v-2-mdx" */),
  "component---src-pages-revistas-numero-47-aspnet-core-como-configurar-tu-aplicacion-para-despliegue-en-una-granja-de-servidores-mdx": () => import("./../../../src/pages/revistas/numero-47/aspnet-core-como-configurar-tu-aplicacion-para-despliegue-en-una-granja-de-servidores.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-47-aspnet-core-como-configurar-tu-aplicacion-para-despliegue-en-una-granja-de-servidores-mdx" */),
  "component---src-pages-revistas-numero-47-como-versionar-nuestro-producto-de-forma-automatica-usando-git-y-azure-devops-mdx": () => import("./../../../src/pages/revistas/numero-47/como-versionar-nuestro-producto-de-forma-automatica-usando-git-y-azure-devops.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-47-como-versionar-nuestro-producto-de-forma-automatica-usando-git-y-azure-devops-mdx" */),
  "component---src-pages-revistas-numero-47-de-office-365-a-employee-experience-platform-pasando-por-myanalytics-y-workplace-analytics-mdx": () => import("./../../../src/pages/revistas/numero-47/de-office-365-a-employee-experience-platform-pasando-por-myanalytics-y-workplace-analytics.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-47-de-office-365-a-employee-experience-platform-pasando-por-myanalytics-y-workplace-analytics-mdx" */),
  "component---src-pages-revistas-numero-47-entrevista-a-juan-camilo-martinez-velez-mdx": () => import("./../../../src/pages/revistas/numero-47/entrevista-a-juan-camilo-martinez-velez.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-47-entrevista-a-juan-camilo-martinez-velez-mdx" */),
  "component---src-pages-revistas-numero-47-entrevista-a-krasis-mdx": () => import("./../../../src/pages/revistas/numero-47/entrevista-a-krasis.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-47-entrevista-a-krasis-mdx" */),
  "component---src-pages-revistas-numero-47-graphapi-y-power-automate-mdx": () => import("./../../../src/pages/revistas/numero-47/graphapi-y-power-automate.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-47-graphapi-y-power-automate-mdx" */),
  "component---src-pages-revistas-numero-47-index-mdx": () => import("./../../../src/pages/revistas/numero-47/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-47-index-mdx" */),
  "component---src-pages-revistas-numero-47-manejando-listas-de-datos-en-powerapps-mdx": () => import("./../../../src/pages/revistas/numero-47/manejando-listas-de-datos-en-powerapps.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-47-manejando-listas-de-datos-en-powerapps-mdx" */),
  "component---src-pages-revistas-numero-47-microsoft-graph-net-sdk-al-descubierto-mdx": () => import("./../../../src/pages/revistas/numero-47/microsoft-graph-net-sdk-al-descubierto.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-47-microsoft-graph-net-sdk-al-descubierto-mdx" */),
  "component---src-pages-revistas-numero-47-power-virtual-agents-qna-maker-mdx": () => import("./../../../src/pages/revistas/numero-47/power-virtual-agents-qna-maker.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-47-power-virtual-agents-qna-maker-mdx" */),
  "component---src-pages-revistas-numero-47-sharepoint-site-performance-page-mdx": () => import("./../../../src/pages/revistas/numero-47/sharepoint-site-performance-page.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-47-sharepoint-site-performance-page-mdx" */),
  "component---src-pages-revistas-numero-48-aprobaciones-rapidas-en-microsoft-teams-con-la-app-de-approvals-mdx": () => import("./../../../src/pages/revistas/numero-48/aprobaciones-rapidas-en-microsoft-teams-con-la-app-de-approvals.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-48-aprobaciones-rapidas-en-microsoft-teams-con-la-app-de-approvals-mdx" */),
  "component---src-pages-revistas-numero-48-azure-functions-best-practices-mdx": () => import("./../../../src/pages/revistas/numero-48/azure-functions-best-practices.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-48-azure-functions-best-practices-mdx" */),
  "component---src-pages-revistas-numero-48-azure-maps-mdx": () => import("./../../../src/pages/revistas/numero-48/azure-maps.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-48-azure-maps-mdx" */),
  "component---src-pages-revistas-numero-48-componentes-en-powerapps-mdx": () => import("./../../../src/pages/revistas/numero-48/componentes-en-powerapps.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-48-componentes-en-powerapps-mdx" */),
  "component---src-pages-revistas-numero-48-entrevista-guitaca-publishers-mdx": () => import("./../../../src/pages/revistas/numero-48/entrevista-guitaca-publishers.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-48-entrevista-guitaca-publishers-mdx" */),
  "component---src-pages-revistas-numero-48-entrevista-rocio-romero-mdx": () => import("./../../../src/pages/revistas/numero-48/entrevista-rocio-romero.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-48-entrevista-rocio-romero-mdx" */),
  "component---src-pages-revistas-numero-48-event-grid-partner-mdx": () => import("./../../../src/pages/revistas/numero-48/event-grid-partner.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-48-event-grid-partner-mdx" */),
  "component---src-pages-revistas-numero-48-iconos-vistas-model-driven-mdx": () => import("./../../../src/pages/revistas/numero-48/iconos_vistas_model_driven.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-48-iconos-vistas-model-driven-mdx" */),
  "component---src-pages-revistas-numero-48-implementar-throotling-en-nuestra-api-mdx": () => import("./../../../src/pages/revistas/numero-48/implementar-throotling-en-nuestra-api.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-48-implementar-throotling-en-nuestra-api-mdx" */),
  "component---src-pages-revistas-numero-48-index-mdx": () => import("./../../../src/pages/revistas/numero-48/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-48-index-mdx" */),
  "component---src-pages-revistas-numero-48-microsoft-clarity-o-como-espiar-a-tus-usuarios-mdx": () => import("./../../../src/pages/revistas/numero-48/microsoft-clarity-o-como-espiar-a-tus-usuarios.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-48-microsoft-clarity-o-como-espiar-a-tus-usuarios-mdx" */),
  "component---src-pages-revistas-numero-48-pnp-core-que-hay-de-nuevo-viejo-mdx": () => import("./../../../src/pages/revistas/numero-48/pnp-core-que-hay-de-nuevo-viejo.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-48-pnp-core-que-hay-de-nuevo-viejo-mdx" */),
  "component---src-pages-revistas-numero-48-redes-generativas-antagonicas-mdx": () => import("./../../../src/pages/revistas/numero-48/redes-generativas-antagonicas.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-48-redes-generativas-antagonicas-mdx" */),
  "component---src-pages-revistas-numero-48-restaurar-elementos-de-la-papelera-con-powershell-mdx": () => import("./../../../src/pages/revistas/numero-48/restaurar-elementos-de-la-papelera-con-powershell.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-48-restaurar-elementos-de-la-papelera-con-powershell-mdx" */),
  "component---src-pages-revistas-numero-49-cambios-de-licenciamiento-para-azuread-external-identities-mdx": () => import("./../../../src/pages/revistas/numero-49/cambios-de-licenciamiento-para-azuread-external-identities.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-49-cambios-de-licenciamiento-para-azuread-external-identities-mdx" */),
  "component---src-pages-revistas-numero-49-configuration-as-code-mdx": () => import("./../../../src/pages/revistas/numero-49/configuration-as-code.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-49-configuration-as-code-mdx" */),
  "component---src-pages-revistas-numero-49-entrevista-edgardalejos-mdx": () => import("./../../../src/pages/revistas/numero-49/entrevista-edgardalejos.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-49-entrevista-edgardalejos-mdx" */),
  "component---src-pages-revistas-numero-49-entrevista-enovel-mdx": () => import("./../../../src/pages/revistas/numero-49/entrevista-enovel.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-49-entrevista-enovel-mdx" */),
  "component---src-pages-revistas-numero-49-index-mdx": () => import("./../../../src/pages/revistas/numero-49/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-49-index-mdx" */),
  "component---src-pages-revistas-numero-49-introduccion-a-azure-identity-sdk-y-uso-con-ms-graph-api-mdx": () => import("./../../../src/pages/revistas/numero-49/introduccion-a-azure-identity-sdk-y-uso-con-ms-graph-api.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-49-introduccion-a-azure-identity-sdk-y-uso-con-ms-graph-api-mdx" */),
  "component---src-pages-revistas-numero-49-m-365-groups-dataverse-mdx": () => import("./../../../src/pages/revistas/numero-49/m365-groups-dataverse.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-49-m-365-groups-dataverse-mdx" */),
  "component---src-pages-revistas-numero-49-mapas-y-mas-mapas-mdx": () => import("./../../../src/pages/revistas/numero-49/mapas-y-mas-mapas.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-49-mapas-y-mas-mapas-mdx" */),
  "component---src-pages-revistas-numero-49-power-automate-custom-connectors-mdx": () => import("./../../../src/pages/revistas/numero-49/power-automate-custom-connectors.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-49-power-automate-custom-connectors-mdx" */),
  "component---src-pages-revistas-numero-49-primera-aproximacion-a-custom-pages-mdx": () => import("./../../../src/pages/revistas/numero-49/primera-aproximacion-a-custom-pages.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-49-primera-aproximacion-a-custom-pages-mdx" */),
  "component---src-pages-revistas-numero-49-probando-sharepoint-syntex-con-la-plantilla-de-syntex-content-center-mdx": () => import("./../../../src/pages/revistas/numero-49/probando-sharepoint-syntex-con-la-plantilla-de-syntex-content-center.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-49-probando-sharepoint-syntex-con-la-plantilla-de-syntex-content-center-mdx" */),
  "component---src-pages-revistas-numero-49-redes-generativas-antagonicas-ii-mdx": () => import("./../../../src/pages/revistas/numero-49/redes-generativas-antagonicas-ii.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-49-redes-generativas-antagonicas-ii-mdx" */),
  "component---src-pages-revistas-numero-5-index-mdx": () => import("./../../../src/pages/revistas/numero-5/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-5-index-mdx" */),
  "component---src-pages-revistas-numero-50-adaptive-card-extensions-en-spfx-v-2-mdx": () => import("./../../../src/pages/revistas/numero-50/adaptive-card-extensions-en-spfx_v2.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-50-adaptive-card-extensions-en-spfx-v-2-mdx" */),
  "component---src-pages-revistas-numero-50-aibuilder-powerapps-piedrapapeltijera-mdx": () => import("./../../../src/pages/revistas/numero-50/aibuilder_powerapps_piedrapapeltijera.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-50-aibuilder-powerapps-piedrapapeltijera-mdx" */),
  "component---src-pages-revistas-numero-50-anadiendo-firma-electronica-a-aprobaciones-con-approvals-en-teams-mdx": () => import("./../../../src/pages/revistas/numero-50/anadiendo-firma-electronica-a-aprobaciones-con-approvals-en-teams.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-50-anadiendo-firma-electronica-a-aprobaciones-con-approvals-en-teams-mdx" */),
  "component---src-pages-revistas-numero-50-azure-functions-deep-dive-mdx": () => import("./../../../src/pages/revistas/numero-50/azure-functions-deep-dive.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-50-azure-functions-deep-dive-mdx" */),
  "component---src-pages-revistas-numero-50-entrevista-diana-mdx": () => import("./../../../src/pages/revistas/numero-50/entrevista-diana.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-50-entrevista-diana-mdx" */),
  "component---src-pages-revistas-numero-50-etica-en-la-ia-mdx": () => import("./../../../src/pages/revistas/numero-50/etica-en-la-ia.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-50-etica-en-la-ia-mdx" */),
  "component---src-pages-revistas-numero-50-extendiendo-viva-connections-con-spfx-mdx": () => import("./../../../src/pages/revistas/numero-50/extendiendo-viva-connections-con-spfx.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-50-extendiendo-viva-connections-con-spfx-mdx" */),
  "component---src-pages-revistas-numero-50-fakers-mdx": () => import("./../../../src/pages/revistas/numero-50/fakers.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-50-fakers-mdx" */),
  "component---src-pages-revistas-numero-50-index-mdx": () => import("./../../../src/pages/revistas/numero-50/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-50-index-mdx" */),
  "component---src-pages-revistas-numero-50-intro-a-purview-mdx": () => import("./../../../src/pages/revistas/numero-50/intro-a-purview.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-50-intro-a-purview-mdx" */),
  "component---src-pages-revistas-numero-50-introduccion-a-microsoft-viva-o-employee-experience-platform-mdx": () => import("./../../../src/pages/revistas/numero-50/introduccion-a-microsoft-viva-o-employee-experience-platform.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-50-introduccion-a-microsoft-viva-o-employee-experience-platform-mdx" */),
  "component---src-pages-revistas-numero-50-lo-nuevo-en-sql-server-2022-mdx": () => import("./../../../src/pages/revistas/numero-50/lo-nuevo-en-sql-server-2022.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-50-lo-nuevo-en-sql-server-2022-mdx" */),
  "component---src-pages-revistas-numero-50-powerautomatepowerbiapi-mdx": () => import("./../../../src/pages/revistas/numero-50/powerautomatepowerbiapi.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-50-powerautomatepowerbiapi-mdx" */),
  "component---src-pages-revistas-numero-50-pva-custom-question-answering-mdx": () => import("./../../../src/pages/revistas/numero-50/pva-custom-question-answering.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-50-pva-custom-question-answering-mdx" */),
  "component---src-pages-revistas-numero-50-real-time-powerapps-mdx": () => import("./../../../src/pages/revistas/numero-50/real-time-powerapps.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-50-real-time-powerapps-mdx" */),
  "component---src-pages-revistas-numero-50-source-generators-mdx": () => import("./../../../src/pages/revistas/numero-50/source_generators.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-50-source-generators-mdx" */),
  "component---src-pages-revistas-numero-51-administrando-microsoft-365-desde-microsoft-teams-mdx": () => import("./../../../src/pages/revistas/numero-51/administrando-microsoft-365-desde-microsoft-teams.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-51-administrando-microsoft-365-desde-microsoft-teams-mdx" */),
  "component---src-pages-revistas-numero-51-azure-ad-cae-mdx": () => import("./../../../src/pages/revistas/numero-51/azure-ad-cae.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-51-azure-ad-cae-mdx" */),
  "component---src-pages-revistas-numero-51-buenas-practicas-en-proyectos-spfx-mdx": () => import("./../../../src/pages/revistas/numero-51/buenas-practicas-en-proyectos-spfx.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-51-buenas-practicas-en-proyectos-spfx-mdx" */),
  "component---src-pages-revistas-numero-51-colaborar-en-canvan-powerapss-mdx": () => import("./../../../src/pages/revistas/numero-51/colaborar-en-canvan-powerapss.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-51-colaborar-en-canvan-powerapss-mdx" */),
  "component---src-pages-revistas-numero-51-como-migrar-tu-file-server-a-sharepoint-utilizando-la-spmt-mdx": () => import("./../../../src/pages/revistas/numero-51/como-migrar-tu-file-server-a-sharepoint-utilizando-la-spmt.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-51-como-migrar-tu-file-server-a-sharepoint-utilizando-la-spmt-mdx" */),
  "component---src-pages-revistas-numero-51-componentes-de-codigo-en-power-apps-portals-mdx": () => import("./../../../src/pages/revistas/numero-51/componentes-de-codigo-en-power-apps-portals.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-51-componentes-de-codigo-en-power-apps-portals-mdx" */),
  "component---src-pages-revistas-numero-51-entrevista-axazure-mdx": () => import("./../../../src/pages/revistas/numero-51/entrevista-axazure.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-51-entrevista-axazure-mdx" */),
  "component---src-pages-revistas-numero-51-entrevista-miguel-llorca-mdx": () => import("./../../../src/pages/revistas/numero-51/entrevista-miguel-llorca.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-51-entrevista-miguel-llorca-mdx" */),
  "component---src-pages-revistas-numero-51-index-mdx": () => import("./../../../src/pages/revistas/numero-51/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-51-index-mdx" */),
  "component---src-pages-revistas-numero-51-microsoft-graph-toolkit-templating-mdx": () => import("./../../../src/pages/revistas/numero-51/microsoft-graph-toolkit-templating.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-51-microsoft-graph-toolkit-templating-mdx" */),
  "component---src-pages-revistas-numero-51-server-rendering-reactjs-mdx": () => import("./../../../src/pages/revistas/numero-51/server-rendering-reactjs.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-51-server-rendering-reactjs-mdx" */),
  "component---src-pages-revistas-numero-51-spfx-114-novedades-destacadas-e-imagehelper-api-a-fondo-mdx": () => import("./../../../src/pages/revistas/numero-51/spfx-114-novedades-destacadas-e-imagehelper-api-a-fondo.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-51-spfx-114-novedades-destacadas-e-imagehelper-api-a-fondo-mdx" */),
  "component---src-pages-revistas-numero-51-testeo-powershell-pester-mdx": () => import("./../../../src/pages/revistas/numero-51/testeo-powershell-pester.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-51-testeo-powershell-pester-mdx" */),
  "component---src-pages-revistas-numero-51-tips-and-tricks-en-azure-api-management-mdx": () => import("./../../../src/pages/revistas/numero-51/tips-and-tricks-en-azure-api-management.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-51-tips-and-tricks-en-azure-api-management-mdx" */),
  "component---src-pages-revistas-numero-52-azure-functions-and-azure-web-pubsub-mdx": () => import("./../../../src/pages/revistas/numero-52/azure-functions-and-azure-web-pubsub.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-52-azure-functions-and-azure-web-pubsub-mdx" */),
  "component---src-pages-revistas-numero-52-azure-static-web-apps-deploy-from-azure-devops-mdx": () => import("./../../../src/pages/revistas/numero-52/azure-static-web-apps-deploy-from-azure-devops.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-52-azure-static-web-apps-deploy-from-azure-devops-mdx" */),
  "component---src-pages-revistas-numero-52-azureappconfig-mdx": () => import("./../../../src/pages/revistas/numero-52/azureappconfig.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-52-azureappconfig-mdx" */),
  "component---src-pages-revistas-numero-52-canales-compartidos-microsoft-teams-parte-i-mdx": () => import("./../../../src/pages/revistas/numero-52/canales-compartidos-microsoft-teams-parte-i.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-52-canales-compartidos-microsoft-teams-parte-i-mdx" */),
  "component---src-pages-revistas-numero-52-docker-developer-office-365-mdx": () => import("./../../../src/pages/revistas/numero-52/docker-developer-office365.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-52-docker-developer-office-365-mdx" */),
  "component---src-pages-revistas-numero-52-entrevista-ana-ines-urrutia-mdx": () => import("./../../../src/pages/revistas/numero-52/entrevista-ana-ines-urrutia.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-52-entrevista-ana-ines-urrutia-mdx" */),
  "component---src-pages-revistas-numero-52-enviar-una-adaptive-card-por-teams-y-esperar-su-respuestav-3-mdx": () => import("./../../../src/pages/revistas/numero-52/enviar-una-adaptive-card-por-teams-y-esperar-su-respuestav3.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-52-enviar-una-adaptive-card-por-teams-y-esperar-su-respuestav-3-mdx" */),
  "component---src-pages-revistas-numero-52-index-mdx": () => import("./../../../src/pages/revistas/numero-52/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-52-index-mdx" */),
  "component---src-pages-revistas-numero-52-microsoft-graph-beta-profile-endpoint-mdx": () => import("./../../../src/pages/revistas/numero-52/microsoft-graph-beta-profile-endpoint.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-52-microsoft-graph-beta-profile-endpoint-mdx" */),
  "component---src-pages-revistas-numero-52-probando-la-wrap-feature-mdx": () => import("./../../../src/pages/revistas/numero-52/probando-la-wrap-feature.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-52-probando-la-wrap-feature-mdx" */),
  "component---src-pages-revistas-numero-53-asp-net-core-implementando-un-proveedor-de-configuracion-personalizado-mdx": () => import("./../../../src/pages/revistas/numero-53/asp.net-core-implementando-un-proveedor-de-configuracion-personalizado.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-53-asp-net-core-implementando-un-proveedor-de-configuracion-personalizado-mdx" */),
  "component---src-pages-revistas-numero-53-azure-container-apps-mdx": () => import("./../../../src/pages/revistas/numero-53/azure-container-apps.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-53-azure-container-apps-mdx" */),
  "component---src-pages-revistas-numero-53-entrevista-toni-granell-mdx": () => import("./../../../src/pages/revistas/numero-53/entrevista-toni-granell.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-53-entrevista-toni-granell-mdx" */),
  "component---src-pages-revistas-numero-53-enviar-email-con-alias-mdx": () => import("./../../../src/pages/revistas/numero-53/enviar-email-con-alias.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-53-enviar-email-con-alias-mdx" */),
  "component---src-pages-revistas-numero-53-express-design-powerapss-mdx": () => import("./../../../src/pages/revistas/numero-53/express-design-powerapss.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-53-express-design-powerapss-mdx" */),
  "component---src-pages-revistas-numero-53-incrementar-las-visitas-de-una-pagina-con-pad-mdx": () => import("./../../../src/pages/revistas/numero-53/incrementar-las-visitas-de-una-pagina-con-pad.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-53-incrementar-las-visitas-de-una-pagina-con-pad-mdx" */),
  "component---src-pages-revistas-numero-53-index-mdx": () => import("./../../../src/pages/revistas/numero-53/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-53-index-mdx" */),
  "component---src-pages-revistas-numero-53-introduccion-a-light-gbm-y-gradient-boosting-mdx": () => import("./../../../src/pages/revistas/numero-53/introduccion-a-light-gbm-y-gradient-boosting.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-53-introduccion-a-light-gbm-y-gradient-boosting-mdx" */),
  "component---src-pages-revistas-numero-53-llamando-a-ms-graph-api-con-permisos-delegados-en-procesos-background-mdx": () => import("./../../../src/pages/revistas/numero-53/llamando-a-ms-graph-api-con-permisos-delegados-en-procesos-background.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-53-llamando-a-ms-graph-api-con-permisos-delegados-en-procesos-background-mdx" */),
  "component---src-pages-revistas-numero-53-solicitar-y-compartir-actualizaciones-con-updates-en-teams-mdx": () => import("./../../../src/pages/revistas/numero-53/solicitar-y-compartir-actualizaciones-con-updates-en-teams.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-53-solicitar-y-compartir-actualizaciones-con-updates-en-teams-mdx" */),
  "component---src-pages-revistas-numero-53-spfx-form-customizer-mdx": () => import("./../../../src/pages/revistas/numero-53/spfx-form-customizer.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-53-spfx-form-customizer-mdx" */),
  "component---src-pages-revistas-numero-53-vas-a-ser-sustituido-por-una-inteligencia-artificial-mdx": () => import("./../../../src/pages/revistas/numero-53/vas-a-ser-sustituido-por-una-inteligencia-artificial.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-53-vas-a-ser-sustituido-por-una-inteligencia-artificial-mdx" */),
  "component---src-pages-revistas-numero-54-asp-net-core-implementando-un-proveedor-de-configuracion-personalizado-mdx": () => import("./../../../src/pages/revistas/numero-54/asp.net-core-implementando-un-proveedor-de-configuracion-personalizado.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-54-asp-net-core-implementando-un-proveedor-de-configuracion-personalizado-mdx" */),
  "component---src-pages-revistas-numero-54-azureadgovernanceapp-mdx": () => import("./../../../src/pages/revistas/numero-54/azureadgovernanceapp.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-54-azureadgovernanceapp-mdx" */),
  "component---src-pages-revistas-numero-54-combinandocsharppowershell-mdx": () => import("./../../../src/pages/revistas/numero-54/combinandocsharppowershell.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-54-combinandocsharppowershell-mdx" */),
  "component---src-pages-revistas-numero-54-de-openai-y-avances-de-la-inteligencia-artificial-mdx": () => import("./../../../src/pages/revistas/numero-54/de-openai-y-avances-de-la-inteligencia-artificial.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-54-de-openai-y-avances-de-la-inteligencia-artificial-mdx" */),
  "component---src-pages-revistas-numero-54-entrevista-alex-rostan-mdx": () => import("./../../../src/pages/revistas/numero-54/entrevista-alex-rostan.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-54-entrevista-alex-rostan-mdx" */),
  "component---src-pages-revistas-numero-54-generar-un-codigo-de-barras-en-sharepoint-y-consumirlo-en-power-apps-mdx": () => import("./../../../src/pages/revistas/numero-54/generar-un-codigo-de-barras-en-sharepoint-y-consumirlo-en-power-apps.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-54-generar-un-codigo-de-barras-en-sharepoint-y-consumirlo-en-power-apps-mdx" */),
  "component---src-pages-revistas-numero-54-hacking-power-platform-mdx": () => import("./../../../src/pages/revistas/numero-54/hacking-power-platform.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-54-hacking-power-platform-mdx" */),
  "component---src-pages-revistas-numero-54-index-mdx": () => import("./../../../src/pages/revistas/numero-54/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-54-index-mdx" */),
  "component---src-pages-revistas-numero-54-introduccion-a-kiota-generando-sdks-para-apis-en-minutos-mdx": () => import("./../../../src/pages/revistas/numero-54/introduccion-a-kiota-generando-sdks-para-apis-en-minutos.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-54-introduccion-a-kiota-generando-sdks-para-apis-en-minutos-mdx" */),
  "component---src-pages-revistas-numero-54-keda-mdx": () => import("./../../../src/pages/revistas/numero-54/keda.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-54-keda-mdx" */),
  "component---src-pages-revistas-numero-54-migracion-de-stream-clasico-a-stream-en-sharepoint-mdx": () => import("./../../../src/pages/revistas/numero-54/migracion-de-stream-clasico-a-stream-en-sharepoint.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-54-migracion-de-stream-clasico-a-stream-en-sharepoint-mdx" */),
  "component---src-pages-revistas-numero-54-powerplatform-mdx": () => import("./../../../src/pages/revistas/numero-54/powerplatform.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-54-powerplatform-mdx" */),
  "component---src-pages-revistas-numero-54-teams-live-translation-mdx": () => import("./../../../src/pages/revistas/numero-54/teams-live-translation.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-54-teams-live-translation-mdx" */),
  "component---src-pages-revistas-numero-55-appregvsentapplication-mdx": () => import("./../../../src/pages/revistas/numero-55/appregvsentapplication.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-55-appregvsentapplication-mdx" */),
  "component---src-pages-revistas-numero-55-azure-openai-service-mdx": () => import("./../../../src/pages/revistas/numero-55/azure-openai-service.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-55-azure-openai-service-mdx" */),
  "component---src-pages-revistas-numero-55-construye-tu-propio-chatgpt-mdx": () => import("./../../../src/pages/revistas/numero-55/construye-tu-propio-chatgpt.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-55-construye-tu-propio-chatgpt-mdx" */),
  "component---src-pages-revistas-numero-55-descifrando-los-azure-saving-plan-mdx": () => import("./../../../src/pages/revistas/numero-55/descifrando-los-azure-saving-plan.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-55-descifrando-los-azure-saving-plan-mdx" */),
  "component---src-pages-revistas-numero-55-editorial-compartimoss-55-mdx": () => import("./../../../src/pages/revistas/numero-55/editorial_compartimoss_55.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-55-editorial-compartimoss-55-mdx" */),
  "component---src-pages-revistas-numero-55-entrevista-samantha-villarreal-torres-mdx": () => import("./../../../src/pages/revistas/numero-55/entrevista-samantha-villarreal-torres.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-55-entrevista-samantha-villarreal-torres-mdx" */),
  "component---src-pages-revistas-numero-55-index-mdx": () => import("./../../../src/pages/revistas/numero-55/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-55-index-mdx" */),
  "component---src-pages-revistas-numero-55-maximizando-el-rendimiento-de-azure-synapse-mdx": () => import("./../../../src/pages/revistas/numero-55/maximizando-el-rendimiento-de-azure-synapse.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-55-maximizando-el-rendimiento-de-azure-synapse-mdx" */),
  "component---src-pages-revistas-numero-55-nueva-home-de-viva-connections-mdx": () => import("./../../../src/pages/revistas/numero-55/nueva-home-de-viva-connections.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-55-nueva-home-de-viva-connections-mdx" */),
  "component---src-pages-revistas-numero-55-powervirtualagents-chatgpt-mdx": () => import("./../../../src/pages/revistas/numero-55/powervirtualagents-chatgpt.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-55-powervirtualagents-chatgpt-mdx" */),
  "component---src-pages-revistas-numero-55-yarp-y-dapr-creando-un-apigateway-para-nuestros-microservicios-mdx": () => import("./../../../src/pages/revistas/numero-55/yarp-y-dapr-creando-un-apigateway-para-nuestros-microservicios.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-55-yarp-y-dapr-creando-un-apigateway-para-nuestros-microservicios-mdx" */),
  "component---src-pages-revistas-numero-56-azure-functions-como-api-de-azure-static-web-apps-mdx": () => import("./../../../src/pages/revistas/numero-56/azure-functions-como-api-de-azure-static-web-apps.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-56-azure-functions-como-api-de-azure-static-web-apps-mdx" */),
  "component---src-pages-revistas-numero-56-chatgpt-plugins-mdx": () => import("./../../../src/pages/revistas/numero-56/chatgpt-plugins.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-56-chatgpt-plugins-mdx" */),
  "component---src-pages-revistas-numero-56-cloud-native-semantic-kernel-mdx": () => import("./../../../src/pages/revistas/numero-56/cloud-native-semantic-kernel.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-56-cloud-native-semantic-kernel-mdx" */),
  "component---src-pages-revistas-numero-56-custom-connectors-mdx": () => import("./../../../src/pages/revistas/numero-56/custom-connectors.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-56-custom-connectors-mdx" */),
  "component---src-pages-revistas-numero-56-entrevista-pablo-di-loreto-mdx": () => import("./../../../src/pages/revistas/numero-56/entrevista_pablo_di_loreto.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-56-entrevista-pablo-di-loreto-mdx" */),
  "component---src-pages-revistas-numero-56-index-mdx": () => import("./../../../src/pages/revistas/numero-56/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-56-index-mdx" */),
  "component---src-pages-revistas-numero-56-integrando-tu-azure-notification-hub-con-dapr-mdx": () => import("./../../../src/pages/revistas/numero-56/integrando-tu-azure-notification-hub-con-dapr.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-56-integrando-tu-azure-notification-hub-con-dapr-mdx" */),
  "component---src-pages-revistas-numero-56-microsoft-graph-net-sdk-v-5-novedades-y-breaking-changes-mdx": () => import("./../../../src/pages/revistas/numero-56/microsoft-graph-net-sdk-v5-novedades-y-breaking-changes.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-56-microsoft-graph-net-sdk-v-5-novedades-y-breaking-changes-mdx" */),
  "component---src-pages-revistas-numero-56-nueva-files-app-para-microsoft-teams-mdx": () => import("./../../../src/pages/revistas/numero-56/nueva-files-app-para-microsoft-teams.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-56-nueva-files-app-para-microsoft-teams-mdx" */),
  "component---src-pages-revistas-numero-56-polyglot-notebooks-mdx": () => import("./../../../src/pages/revistas/numero-56/polyglot_notebooks.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-56-polyglot-notebooks-mdx" */),
  "component---src-pages-revistas-numero-57-ai-builder-gpt-feature-mdx": () => import("./../../../src/pages/revistas/numero-57/ai-builder-gpt-feature.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-57-ai-builder-gpt-feature-mdx" */),
  "component---src-pages-revistas-numero-57-azure-load-testing-mdx": () => import("./../../../src/pages/revistas/numero-57/azure-load-testing.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-57-azure-load-testing-mdx" */),
  "component---src-pages-revistas-numero-57-configurando-varias-experiencias-de-microsoft-viva-connections-en-un-tenant-de-microsoft-365-mdx": () => import("./../../../src/pages/revistas/numero-57/configurando-varias-experiencias-de-microsoft-viva-connections-en-un-tenant-de-microsoft-365.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-57-configurando-varias-experiencias-de-microsoft-viva-connections-en-un-tenant-de-microsoft-365-mdx" */),
  "component---src-pages-revistas-numero-57-de-ia-iot-y-drones-mdx": () => import("./../../../src/pages/revistas/numero-57/de-ia-iot-y-drones.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-57-de-ia-iot-y-drones-mdx" */),
  "component---src-pages-revistas-numero-57-entrevista-ferranchopo-mdx": () => import("./../../../src/pages/revistas/numero-57/entrevista-ferranchopo.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-57-entrevista-ferranchopo-mdx" */),
  "component---src-pages-revistas-numero-57-index-mdx": () => import("./../../../src/pages/revistas/numero-57/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-57-index-mdx" */),
  "component---src-pages-revistas-numero-57-introduccion-a-microsoft-fabric-mdx": () => import("./../../../src/pages/revistas/numero-57/introduccion-a-microsoft-fabric.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-57-introduccion-a-microsoft-fabric-mdx" */),
  "component---src-pages-revistas-numero-57-minimalapi-mdx": () => import("./../../../src/pages/revistas/numero-57/minimalapi.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-57-minimalapi-mdx" */),
  "component---src-pages-revistas-numero-57-ms-search-display-templates-mdx": () => import("./../../../src/pages/revistas/numero-57/ms-search-display-templates.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-57-ms-search-display-templates-mdx" */),
  "component---src-pages-revistas-numero-57-pva-ia-generativa-mdx": () => import("./../../../src/pages/revistas/numero-57/pva-ia-generativa.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-57-pva-ia-generativa-mdx" */),
  "component---src-pages-revistas-numero-57-usando-azure-openai-api-desde-spfx-en-modo-stream-mdx": () => import("./../../../src/pages/revistas/numero-57/usando-azure-openai-api-desde-spfx-en-modo-stream.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-57-usando-azure-openai-api-desde-spfx-en-modo-stream-mdx" */),
  "component---src-pages-revistas-numero-58-de-ia-iot-y-drones-parte-2-mdx": () => import("./../../../src/pages/revistas/numero-58/de-ia-iot-y-drones-parte-2.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-58-de-ia-iot-y-drones-parte-2-mdx" */),
  "component---src-pages-revistas-numero-58-entrevista-a-fatimablancas-mdx": () => import("./../../../src/pages/revistas/numero-58/entrevista_a_fatimablancas.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-58-entrevista-a-fatimablancas-mdx" */),
  "component---src-pages-revistas-numero-58-entrevista-empresa-spbox-mdx": () => import("./../../../src/pages/revistas/numero-58/entrevista_empresa_spbox.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-58-entrevista-empresa-spbox-mdx" */),
  "component---src-pages-revistas-numero-58-gpt-4-vision-mdx": () => import("./../../../src/pages/revistas/numero-58/gpt-4-vision.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-58-gpt-4-vision-mdx" */),
  "component---src-pages-revistas-numero-58-index-mdx": () => import("./../../../src/pages/revistas/numero-58/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-58-index-mdx" */),
  "component---src-pages-revistas-numero-58-maximizatucluster-mdx": () => import("./../../../src/pages/revistas/numero-58/maximizatucluster.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-58-maximizatucluster-mdx" */),
  "component---src-pages-revistas-numero-58-nueva-home-de-viva-connections-mdx": () => import("./../../../src/pages/revistas/numero-58/nueva-home-de-viva-connections.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-58-nueva-home-de-viva-connections-mdx" */),
  "component---src-pages-revistas-numero-58-semantic-kernel-azure-openai-para-novatos-mdx": () => import("./../../../src/pages/revistas/numero-58/semantic-kernel-azure-openai-para-novatos.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-58-semantic-kernel-azure-openai-para-novatos-mdx" */),
  "component---src-pages-revistas-numero-59-copilot-for-m-365-solution-assessment-mdx": () => import("./../../../src/pages/revistas/numero-59/copilot-for-m365-solution-assessment.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-59-copilot-for-m-365-solution-assessment-mdx" */),
  "component---src-pages-revistas-numero-59-dotnet-8-mdx": () => import("./../../../src/pages/revistas/numero-59/dotnet8.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-59-dotnet-8-mdx" */),
  "component---src-pages-revistas-numero-59-entrevista-a-adrian-de-los-reyes-mdx": () => import("./../../../src/pages/revistas/numero-59/entrevista-a-adrian-de-los-reyes.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-59-entrevista-a-adrian-de-los-reyes-mdx" */),
  "component---src-pages-revistas-numero-59-index-mdx": () => import("./../../../src/pages/revistas/numero-59/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-59-index-mdx" */),
  "component---src-pages-revistas-numero-59-net-aspire-es-un-conjunto-de-herramientas-mdx": () => import("./../../../src/pages/revistas/numero-59/net-aspire-es-un-conjunto-de-herramientas.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-59-net-aspire-es-un-conjunto-de-herramientas-mdx" */),
  "component---src-pages-revistas-numero-59-powerapps-user-functions-mdx": () => import("./../../../src/pages/revistas/numero-59/powerapps-user-functions.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-59-powerapps-user-functions-mdx" */),
  "component---src-pages-revistas-numero-59-prompt-tokens-llm-slm-embedding-mdx": () => import("./../../../src/pages/revistas/numero-59/prompt-tokens-llm-slm-embedding.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-59-prompt-tokens-llm-slm-embedding-mdx" */),
  "component---src-pages-revistas-numero-59-semantic-kernel-function-y-prompt-filters-mdx": () => import("./../../../src/pages/revistas/numero-59/semantic-kernel-function-y-prompt-filters.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-59-semantic-kernel-function-y-prompt-filters-mdx" */),
  "component---src-pages-revistas-numero-59-tarjetas-personalizadas-en-viva-connections-mdx": () => import("./../../../src/pages/revistas/numero-59/tarjetas-personalizadas-en-viva-connections.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-59-tarjetas-personalizadas-en-viva-connections-mdx" */),
  "component---src-pages-revistas-numero-6-index-mdx": () => import("./../../../src/pages/revistas/numero-6/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-6-index-mdx" */),
  "component---src-pages-revistas-numero-60-como-crear-tenants-de-pruebas-para-microsoft-365-intune-powerplatform-y-azure-mdx": () => import("./../../../src/pages/revistas/numero-60/como-crear-tenants-de-pruebas-para-microsoft-365-intune-powerplatform-y-azure.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-60-como-crear-tenants-de-pruebas-para-microsoft-365-intune-powerplatform-y-azure-mdx" */),
  "component---src-pages-revistas-numero-60-copilot-para-microsoft-stream-mdx": () => import("./../../../src/pages/revistas/numero-60/copilot-para-microsoft-stream.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-60-copilot-para-microsoft-stream-mdx" */),
  "component---src-pages-revistas-numero-60-entrevista-marllambi-mdx": () => import("./../../../src/pages/revistas/numero-60/entrevista_marllambi.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-60-entrevista-marllambi-mdx" */),
  "component---src-pages-revistas-numero-60-index-mdx": () => import("./../../../src/pages/revistas/numero-60/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-60-index-mdx" */),
  "component---src-pages-revistas-numero-60-nats-mdx": () => import("./../../../src/pages/revistas/numero-60/nats.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-60-nats-mdx" */),
  "component---src-pages-revistas-numero-60-novedades-en-microsoft-fabric-mdx": () => import("./../../../src/pages/revistas/numero-60/novedades-en-microsoft-fabric.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-60-novedades-en-microsoft-fabric-mdx" */),
  "component---src-pages-revistas-numero-60-power-platform-pipelines-mdx": () => import("./../../../src/pages/revistas/numero-60/power-platform-pipelines.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-60-power-platform-pipelines-mdx" */),
  "component---src-pages-revistas-numero-60-rag-pattern-con-kernelmemory-piece-of-cake-mdx": () => import("./../../../src/pages/revistas/numero-60/rag-pattern-con-kernelmemory-piece-of-cake.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-60-rag-pattern-con-kernelmemory-piece-of-cake-mdx" */),
  "component---src-pages-revistas-numero-60-tarjetas-dinamicas-en-el-dashboard-de-viva-connections-mdx": () => import("./../../../src/pages/revistas/numero-60/tarjetas-dinamicas-en-el-dashboard-de-viva-connections.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-60-tarjetas-dinamicas-en-el-dashboard-de-viva-connections-mdx" */),
  "component---src-pages-revistas-numero-61-argocd-mdx": () => import("./../../../src/pages/revistas/numero-61/argocd.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-61-argocd-mdx" */),
  "component---src-pages-revistas-numero-61-copilot-para-onedrive-for-business-mdx": () => import("./../../../src/pages/revistas/numero-61/copilot-para-onedrive-for-business.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-61-copilot-para-onedrive-for-business-mdx" */),
  "component---src-pages-revistas-numero-61-de-azure-functions-a-aws-lambdas-mdx": () => import("./../../../src/pages/revistas/numero-61/de-azure-functions-a-aws-lambdas.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-61-de-azure-functions-a-aws-lambdas-mdx" */),
  "component---src-pages-revistas-numero-61-entrevista-santiago-porras-mdx": () => import("./../../../src/pages/revistas/numero-61/entrevista-santiago-porras.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-61-entrevista-santiago-porras-mdx" */),
  "component---src-pages-revistas-numero-61-index-mdx": () => import("./../../../src/pages/revistas/numero-61/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-61-index-mdx" */),
  "component---src-pages-revistas-numero-61-microsoft-fabric-azure-data-explorer-mdx": () => import("./../../../src/pages/revistas/numero-61/microsoft-fabric-azure-data-explorer.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-61-microsoft-fabric-azure-data-explorer-mdx" */),
  "component---src-pages-revistas-numero-61-tarjetas-adaptables-en-copilot-mdx": () => import("./../../../src/pages/revistas/numero-61/tarjetas-adaptables-en-copilot.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-61-tarjetas-adaptables-en-copilot-mdx" */),
  "component---src-pages-revistas-numero-62-agentes-de-copilot-para-sitios-de-sharepoint-mdx": () => import("./../../../src/pages/revistas/numero-62/agentes-de-copilot-para-sitios-de-sharepoint.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-62-agentes-de-copilot-para-sitios-de-sharepoint-mdx" */),
  "component---src-pages-revistas-numero-62-argorollouts-mdx": () => import("./../../../src/pages/revistas/numero-62/argorollouts.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-62-argorollouts-mdx" */),
  "component---src-pages-revistas-numero-62-entrevista-a-nicolasbellocamilletti-mdx": () => import("./../../../src/pages/revistas/numero-62/entrevista_a_nicolasbellocamilletti.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-62-entrevista-a-nicolasbellocamilletti-mdx" */),
  "component---src-pages-revistas-numero-62-index-mdx": () => import("./../../../src/pages/revistas/numero-62/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-62-index-mdx" */),
  "component---src-pages-revistas-numero-62-mensajes-proactivos-en-copilot-studio-mdx": () => import("./../../../src/pages/revistas/numero-62/mensajes-proactivos-en-copilot-studio.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-62-mensajes-proactivos-en-copilot-studio-mdx" */),
  "component---src-pages-revistas-numero-62-prueba-tus-copilotos-personalizados-con-power-cat-copilot-studio-kit-mdx": () => import("./../../../src/pages/revistas/numero-62/prueba-tus-copilotos-personalizados-con-power-cat-copilot-studio-kit.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-62-prueba-tus-copilotos-personalizados-con-power-cat-copilot-studio-kit-mdx" */),
  "component---src-pages-revistas-numero-63-administra-microsoft-365-a-otro-nivel-con-copilot-mdx": () => import("./../../../src/pages/revistas/numero-63/administra-microsoft-365-a-otro-nivel-con-copilot.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-63-administra-microsoft-365-a-otro-nivel-con-copilot-mdx" */),
  "component---src-pages-revistas-numero-63-copilot-studio-autenticacion-conectores-personalizados-mdx": () => import("./../../../src/pages/revistas/numero-63/copilot-studio-autenticacion-conectores-personalizados.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-63-copilot-studio-autenticacion-conectores-personalizados-mdx" */),
  "component---src-pages-revistas-numero-63-datos-rot-mdx": () => import("./../../../src/pages/revistas/numero-63/datos-rot.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-63-datos-rot-mdx" */),
  "component---src-pages-revistas-numero-63-dependencias-mdx": () => import("./../../../src/pages/revistas/numero-63/dependencias.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-63-dependencias-mdx" */),
  "component---src-pages-revistas-numero-63-entrevista-mar-pedroche-mdx": () => import("./../../../src/pages/revistas/numero-63/entrevista-mar-pedroche.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-63-entrevista-mar-pedroche-mdx" */),
  "component---src-pages-revistas-numero-63-index-mdx": () => import("./../../../src/pages/revistas/numero-63/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-63-index-mdx" */),
  "component---src-pages-revistas-numero-63-net-ai-template-mdx": () => import("./../../../src/pages/revistas/numero-63/net-ai-template.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-63-net-ai-template-mdx" */),
  "component---src-pages-revistas-numero-7-index-mdx": () => import("./../../../src/pages/revistas/numero-7/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-7-index-mdx" */),
  "component---src-pages-revistas-numero-8-index-mdx": () => import("./../../../src/pages/revistas/numero-8/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-8-index-mdx" */),
  "component---src-pages-revistas-numero-9-index-mdx": () => import("./../../../src/pages/revistas/numero-9/index.mdx" /* webpackChunkName: "component---src-pages-revistas-numero-9-index-mdx" */),
  "component---src-templates-keywords-js": () => import("./../../../src/templates/keywords.js" /* webpackChunkName: "component---src-templates-keywords-js" */)
}

